import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Scrollbars } from 'react-custom-scrollbars';
import * as CommonAxios from "./../CommonAxios";
import moment from "moment";
import styles from "./../../styles/modal.module.css"
import Swal from "sweetalert2";
import Draggable from "react-draggable"
const RunModal = ({ routeArray, nameArray, cancels, chkStart, chkEnd, delStart, delEnd, submit, user }) => {

    const [routeArr, setRouteArr] = useState([]);       // 노선 정보 배열
    const [nameArr, setNameArr] = useState([]);         // 버스(노선) 이름 배열

    const [oriOnArr, setOriOnArr] = useState([]);       // 선택한 노선의 승차정류장 배열
    const [showOnArr, setShowOnArr] = useState([]);     // 보여줄 승차정류장 배열
    const [oriOffArr, setOriOffArr] = useState([]);     // 선택한 노선의 하차정류장 배열
    const [showOffArr, setShowOffArr] = useState([]);   // 보여줄 하차정류장 배열

    const [onVal, setOnVal] = useState("");             // 승차정류장 검색어
    const [offVal, setOffVal] = useState("");           // 하차정류장 검색어

    const [onBool, setOnBool] = useState(false);        // 승차정류장 셀렉트박스 여부
    const [offBool, setOffBool] = useState(false);      // 하차정류장 셀렉트박스 여부

    const [userInfo, setUserInfo] = useState("");

    useEffect(() => {
        if (routeArray != undefined) {
            const sortByRsOrder = (data) => {
                return data.slice().sort((a, b) => a.rs_order - b.rs_order);
            };
            let arr = sortByRsOrder(routeArray);
            setRouteArr(arr);
        }
        if (nameArray != undefined) {
            setNameArr(nameArray);
        }

        setUserInfo(user);
    }, [])

    const [sctName, setSctName] = useState(0);       // 노선 선택 데이터

    const [onNum, setOnNum] = useState(0);          // 선택한 승차정류장의 순번
    const [offNum, setOffNum] = useState(0);        // 선택한 하차정류장의 순번

    // 노선 변경
    const onChangeName = useCallback((e) => {
        let getData = e.target.value;
        setSctName(getData);
    }, [])

    // 노선 선택(변경) 시
    useEffect(() => {
        if (sctName != 0) {
            let arr = routeArr;
            let newArr = arr.filter((a) => a.rs_route_no == sctName);
            let onArr = newArr.slice(0, -1);
            setOriOnArr(onArr);
            setShowOnArr(onArr);
            let offArr = newArr.slice(1);
            setOriOffArr(offArr);
            setShowOffArr(offArr);

            setOnVal("");
            setOffVal("");
            setOnNum(0);
            setOffNum(0);
        } else {
            setOriOnArr([]);
            setShowOnArr([]);
            setOriOffArr([]);
            setShowOffArr([]);
        }
    }, [sctName])

    const onChgArr = (e) => {
        let data = e.target.value;
        setOnVal(data);
    }

    const offChgArr = (e) => {
        let data = e.target.value;
        setOffVal(data);
    }

    useEffect(() => {
        if (onVal != "") {
            if (routeArray.length > 0) {
                let result = oriOnArr.filter((item) =>
                    String(item.stop_name).includes(onVal) ||
                    String(item.stop_number).includes(onVal)
                )
                setShowOnArr(result)
            }
        } else {
            if (routeArray.length > 0) {
                let arr = oriOnArr;
                if (offNum != 0) {
                    let filters = arr.filter((a) => a.rs_order < offNum);
                    setShowOnArr(filters);
                } else {
                    setShowOnArr(oriOnArr);
                }
            } else {
                setShowOnArr([]);
            }
        }
    }, [onVal])

    // 승차정류장 선택 기능
    const sctOnStop = ({ event, data }) => {
        let array = routeArr;
        let ftArr = array.filter((item) => item.stop_number == data && item.rs_route_no == sctName);
        setOnVal(`${ftArr[0].stop_name} ${data}`)
        setOnBool(false);
        let orderNum = ftArr[0].rs_order;
        setOnNum(orderNum)

        let sendData = {
            x: ftArr[0].stop_longitude,
            y: ftArr[0].stop_latitude,
        }
        chkStart(sendData);
    }

    useEffect(() => {
        if (onNum != 0) {
            let arr = oriOffArr;
            let filterArr = arr.filter((a) => a.rs_order > onNum);
            setShowOffArr(filterArr);
        }
    }, [onNum])


    // 승차정류장 데이터 삭제 버튼
    const delSData = () => {
        delStart();
        setOnVal("");
        setOnNum(0);
    }

    const openOnBox = (e) => {
        e.stopPropagation();
        setOnBool(!onBool);
    }

    useEffect(() => {
        if (offVal != "") {
            if (routeArray.length > 0) {
                let result = oriOffArr.filter((item) =>
                    String(item.stop_name).includes(offVal) ||
                    String(item.stop_number).includes(offVal)
                )
                setShowOffArr(result)
            }
        } else {
            if (routeArray.length > 0) {
                let arr = oriOffArr;
                if (onNum != 0) {
                    let filters = arr.filter((a) => a.rs_order > onNum);
                    setShowOffArr(filters);
                } else {
                    setShowOffArr(arr);
                }
            } else {
                setShowOffArr([]);
            }
        }
    }, [offVal])

    // 승차정류장 선택 기능
    const sctOffStop = ({ event, data }) => {
        let array = routeArr;
        let ftArr = array.filter((item) => item.stop_number == data && item.rs_route_no == sctName);
        setOffVal(`${ftArr[0].stop_name} ${data}`);
        setOffBool(false);
        let orderNum = ftArr[0].rs_order;
        console.log(routeArr);
        console.log(data);
        console.log(orderNum + "데이터")
        setOffNum(orderNum)

        let sendData = {
            x: ftArr[0].stop_longitude,
            y: ftArr[0].stop_latitude,
        }
        chkEnd(sendData);
    }

    useEffect(() => {
        if (offNum != 0) {
            let arr = oriOnArr;
            let filterArr = arr.filter((a) => a.rs_order < offNum);
            setShowOnArr(filterArr);
        }
    }, [offNum])


    // 하차정류장 데이터 삭제 버튼
    const delEData = () => {
        delEnd();
        setOffVal("");
        setOffNum(0);
    }

    const openOffBox = (e) => {
        e.stopPropagation();
        setOffBool(!offBool);
    }

    const submitBtn = (e) => {
        e.preventDefault();
        let nowDate = new Date();
        let callCode = 'B' + moment(nowDate).format('YYYYMMDDHHmmss');

        let routes = routeArr.filter((item) => item.rs_route_no == sctName);

        let onNumber = routes.filter((item) => item.rs_order == onNum);
        let offNumber = routes.filter((item) => item.rs_order == offNum);





        if (onNumber.length > 0 && offNumber.length > 0) {

            let sendData = {
                routeNo: sctName, // 노선고유번호
                codeNum: callCode,
                category: 1,
                onStop: onNumber[0].stop_number,
                offStop: offNumber[0].stop_number,
                sendDate: moment(nowDate).format('YYYY-MM-DD HH-mm-ss'),
                order_num: onNum,
                routesArr: routeArr,
                memos: "",
                uniq: userInfo.uniq,
            }

            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/insert_bus_operate",
                sendData,
                function (result) {
                    if (result.messageinfo.message > 0) {
                        let number = result.messageinfo.message;
                        let record = result.messageinfo.message_data2;
                        let datas = {
                            busNum: number,
                            onStop: onNumber[0].stop_number,
                            offStop: offNumber[0].stop_number,
                            recordId: record,
                            status: 'active',
                            callCode: callCode,
                            user_uniq: userInfo.uniq,
                        }

                        Swal.fire({
                            title: "배차 접수중",
                            text: "배차 대기 중입니다. 잠시만 기다려주세요.",
                            icon: "warning",
                            showConfirmButton: false,
                            allowOutsideClick: false

                            // confirmButtonText: "확인",
                            // confirmButtonColor: "#FF0000",
                        });
                        //26일 수정 - 배차 성공 팝업 주석 -> 기사님이 배차 받았으면 배차 성공 팝업창
                        // Swal.fire({
                        //     title: "배차 성공",
                        //     icon: "success",
                        //     message: result.messageinfo.message,
                        //     confirmButtonText: "확인",
                        // }).then((res) => {
                        //     if (res.isConfirmed) {
                        submit(datas);
                        //     }
                        // });
                    } else if (result.messageinfo.message == "실패1") {
                        Swal.fire({
                            title: "배차 실패",
                            text: "해당 노선의 운행중인 버스가 없습니다.",
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    } else if (result.messageinfo.message == "실패2") {
                        Swal.fire({
                            title: "배차 실패",
                            text: "해당 정거장에 배차가 가능한 버스가 없습니다.",
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    } else {
                        Swal.fire({
                            title: "배차 오류",
                            text: result.messageinfo.message,
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    }
                }
            );
        } else {
            Swal.fire({
                title: "배차 실패",
                text: "노선, 승차정류장, 하차정류장을 선택해주세요.",
                icon: "error",
                confirmButtonText: "확인",
                confirmButtonColor: "#FF0000",
            });
        }

    }

    const cancelBtn = () => {
        cancels();
    }
    const [position, setPosition] = useState({ x: 0, y: 0 }); // box의 포지션 값
    // 업데이트 되는 값을 set 해줌
    const trackPos = (data) => {
        setPosition({ x: data.x, y: data.y });
    };
    return (
        <Draggable onDrag={(e, data) => trackPos(data)} >
            <div className={styles.modal_div}>
                <div className={styles.modal_title}>수동배차</div>
                <div className={styles.modal_contents}>
                    <div>
                        <div>노선</div>
                        <select className={styles.list_area} value={sctName} onChange={onChangeName}>
                            <option value={0}>노선을 선택하세요.</option>
                            {nameArr.map((a) => {
                                return (
                                    <option key={a.no} value={a.no}>{a.route_name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <div>승차정류장</div>
                        <div className={styles.list_area}>
                            <input type="text" value={onVal} placeholder='정류장 이름/번호 입력' onChange={(e) => onChgArr(e)} />
                            {onVal &&
                                <IoMdCloseCircle style={{ padding: "0 5px" }} onClick={() => delSData()} />
                            }
                            <FaMagnifyingGlass style={{ float: "right", margin: "2px 0" }} onClick={(e) => openOnBox(e)} />
                            {onBool &&
                                <ul>
                                    {showOnArr.length > 0 ?
                                        (showOnArr.length > 5 ?
                                            <Scrollbars className={styles.ul_area} style={{ width: 298, height: 150 }}>
                                                {showOnArr.map((a) => {
                                                    return (
                                                        <li onClick={(e) => sctOnStop({ event: e, data: a.stop_number })}>
                                                            <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                        </li>
                                                    )
                                                })}
                                            </Scrollbars>
                                            :
                                            showOnArr.map((a) => {
                                                return (
                                                    <li onClick={(e) => sctOnStop({ event: e, data: a.stop_number })}>
                                                        <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                    </li>
                                                )
                                            })
                                        )
                                        :
                                        <li onClick={() => setOnBool(false)} style={{ height: "30px", textAlign: "center", lineHeight: "30px" }}>조회된 결과가 없습니다.</li>
                                    }
                                </ul>
                            }
                        </div>
                    </div>
                    <div>
                        <div>하차정류장</div>
                        <div className={styles.list_area}>
                            <input type="text" value={offVal} placeholder='정류장 이름/번호 입력' onChange={(e) => offChgArr(e)} />
                            {offVal &&
                                <IoMdCloseCircle style={{ padding: "0 5px" }} onClick={() => delEData()} />
                            }
                            <FaMagnifyingGlass style={{ float: "right", margin: "2px 0" }} onClick={(e) => openOffBox(e)} />
                            {offBool &&
                                <ul>
                                    {showOffArr.length > 0 ?
                                        (showOffArr.length > 5 ?
                                            <Scrollbars className={styles.ul_area} style={{ width: 298, height: 150 }}>
                                                {showOffArr.map((a) => {
                                                    return (
                                                        <li onClick={(e) => sctOffStop({ event: e, data: a.stop_number })}>
                                                            <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                        </li>
                                                    )
                                                })}
                                            </Scrollbars>
                                            :
                                            showOffArr.map((a) => {
                                                return (
                                                    <li onClick={(e) => sctOffStop({ event: e, data: a.stop_number })}>
                                                        <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                    </li>
                                                )
                                            })
                                        )
                                        :
                                        <li onClick={() => setOffBool(false)} style={{ height: "30px", textAlign: "center", lineHeight: "30px" }}>조회된 결과가 없습니다.</li>
                                    }
                                </ul>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.modal_btn_area}>
                    <button className={styles.submit_btn} onClick={submitBtn}>배차</button>
                    <button className={styles.cancel_btn} onClick={() => cancelBtn()}>닫기</button>
                </div>
            </div>
        </Draggable>
    )

}

export default RunModal;