import { Link, useLocation } from "react-router-dom";
import "./header.css"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminRecordAxios, CommonAxios } from "../../module/CommonAxios";
import moment from "moment";
import userSlice from "../../slices/user";

const Header = () => {

    const user = useSelector(state => state.user);
    const location = useLocation();
    const dispatch = useDispatch();


    const logout = () => {
        let sendData = {
        }
        let record = {
            record_category: "로그아웃",
            record_user: user.uniq,
            record_text: `로그아웃하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/logout",
            sendData,
            function (result) {
                console.log(result);
                if (result) {
                    AdminRecordAxios(record);
                    dispatch(
                        userSlice.actions.reset({
                        })
                    )
                    // inputView(false);

                    // recentData();
                    // window.alert("저장 완료");

                }
            })
    }
    return (<>

        <div className="hedaer_wrap">
            <div className="header_box">
                {location.pathname.indexOf("main") !== -1 &&
                    <div className="header_logo">
                        <Link to="admin" style={{ color: "#fff" }}>
                            괴산콜버스 콜센터
                        </Link>
                    </div>
                }

                {location.pathname.indexOf("moniter") !== -1 &&
                    <div className="header_logo" style={{ width: "300px" }} >
                        괴산콜버스 공공장소 모니터링
                    </div>
                }

                {(location.pathname.indexOf("main") === -1 && location.pathname.indexOf("moniter") === -1) &&
                    <div className="header_logo">
                        <Link to="admin" style={{ color: "#fff" }}>
                            괴산콜버스 통합관제
                        </Link>
                    </div>
                }

                <nav className="header_nav">
                    {(location.pathname.indexOf("main") === -1 && location.pathname.indexOf("moniter") === -1) ?
                        <ul>
                            {/* <li>
                                <Link to="/main" className={location.pathname.indexOf("main") === -1 ? "header_a" : "header_a view"}>콜센터</Link>
                            </li>
                            <li>
                                <Link to="/moniter" className={location.pathname.indexOf("moniter") === -1 ? "header_a" : "header_a view"}>모니터링</Link>
                            </li> */}
                            <li>
                                <Link to="/appmg/qnamg" className={location.pathname.indexOf("appmg") === -1 ? "header_a" : "header_a view"} >사용자앱관리</Link>
                            </li>
                            <li>
                                <Link to="/operatemg/call" className={location.pathname.indexOf("adminmg") === -1 ? "header_a" : "header_a view"} >운영계정관리</Link></li>
                            <li>
                                <Link to="/busmg/stopmg" className={location.pathname.indexOf("busmg") === -1 ? "header_a" : "header_a view"} >버스관리</Link></li>
                            {/* <li>
                            <Link to="/taximg/taximg" className={location.pathname.indexOf("taximg") === -1 ? "header_a" : "header_a view"} >택시관리</Link></li> */}
                            {/* <li>
                            <Link to="/alarmmg/emmg" className={location.pathname.indexOf("alarmmg") === -1 ? "header_a" : "header_a view"} >알림관리</Link></li> */}
                            <li><Link to="/statistics/bus" className={location.pathname.indexOf("statistics") === -1 ? "header_a" : "header_a view"} >통계</Link></li>

                        </ul>
                        : <ul></ul>}
                    <div className="header_side">
                        <span>{user.id}님</span>
                        <button onClick={logout}>로그아웃</button>
                    </div>
                </nav>
            </div>
        </div></>)
}

export default Header;