import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/appmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";

import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";

const FaqCaInner = () => {
    const navigate = useNavigate();
    const [insertVale, setInsertValue] = useState("");

    const [data, setData] = useState([]);

    const dispatch = useDispatch();





    const [tokenSwitch, setTokenSwitch] = useState(false);


    const getData = () => {


        let sendData = {

            where: "",
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/select_faqc",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    setData(result.messageinfo.message);
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        getData();


    }, [])

    const user = useSelector(state => state.user);


    const changeInsert = useCallback((e) => {
        setInsertValue(e.target.value);
    }, [])

    const onAdd = () => {
        let sendData = {
            faq_name: insertVale,
            faq_order: data[data.length - 1].faq_order + 1,
        }
        let record = {
            record_category: "FAQ 분류관리",
            record_user: user.uniq,
            record_text: `''${insertVale}'' FAQ를 추가하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/faqCa_insert",
            sendData,
            function (result) {
                if (result.result) {


                    AdminRecordAxios(record);
                    getData();
                    setInsertValue("");
                    // recentData();
                    // window.alert("저장 완료");

                }
            })
    }
    const FaqCList = ({ data2 }) => {
        const [inputView, setInputView] = useState(false);
        const [inValue, setInValue] = useState("");
        const inputChange = useCallback((e) => {
            setInValue(e.target.value);
        }, [])
        const downData = () => {
            let nextOrder = data.filter((item) => item.faq_order === data2.faq_order + 1);


            // console.log(nextOrder[0].no)
            // return
            let sendData = {
                no: data2.no,
                nextNo: nextOrder[0].no,
                faq_order: data2.faq_order,
                faqNextOrder: nextOrder[0].faq_order,
            }
            let record = {
                record_category: "FAQ 분류관리",
                record_user: user.uniq,
                record_text: `순서를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/faqCa_update_order",
                sendData,
                function (result) {
                    if (result.result) {

                        inputView(false);
                        inValue("");
                        AdminRecordAxios(record);
                        getData();

                        // recentData();
                        // window.alert("저장 완료");

                    }
                })
        }
        const upData = () => {
            let nextOrder = data.filter((item) => item.faq_order === data2.faq_order - 1);


            // console.log(nextOrder[0].no)
            // return
            let sendData = {
                no: data2.no,
                nextNo: nextOrder[0].no,
                faq_order: data2.faq_order,
                faqNextOrder: nextOrder[0].faq_order,
            }
            let record = {
                record_category: "FAQ 분류관리",
                record_user: user.uniq,
                record_text: `순서를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/faqCa_update_order",
                sendData,
                function (result) {
                    if (result.result) {


                        AdminRecordAxios(record);
                        getData();

                        // recentData();
                        // window.alert("저장 완료");

                    }
                })
        }
        const updateData = () => {
            let sendData = {
                no: data2.no,
                faq_name: inValue,
            }
            let record = {
                record_category: "FAQ 분류관리",
                record_user: user.uniq,
                record_text: `기존 ''${data2.faq_name}''의 이름을 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/faqCa_update",
                sendData,
                function (result) {
                    if (result.result) {

                        // inputView(false);

                        AdminRecordAxios(record);
                        getData();

                        // recentData();
                        // window.alert("저장 완료");

                    }
                })
        }
        const deleteData = () => {
            let sendData = {
                no: data2.no,

            }
            let record = {
                record_category: "FAQ 분류관리",
                record_user: user.uniq,
                record_text: `''${data2.faq_name}''를 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/faqCa_delete",
                sendData,
                function (result) {
                    if (result.result) {

                        AdminRecordAxios(record);

                        getData();








                        // getData();

                        // recentData();
                        // window.alert("저장 완료");

                    }
                })
        }

        const onClick = () => {
            setInputView(true);
            setInValue(data2.faq_name)
        }

        const onCancel = () => {
            setInputView(false);
            setInValue("");
        }
        return <div className={manage.faqc_list_item}>
            <div className={manage.faqc_list_left}>
                {data2.faq_order !== data.length ? <button onClick={downData}><LuArrowDownSquare /></button> : <button></button>}
                {data2.faq_order !== 1 && <button onClick={upData}><LuArrowUpSquare /></button>}
            </div>
            {!inputView && <div className={manage.faqc_list_div}>
                <div className={manage.faqc_list_middle}>
                    <span>{data2.faq_name}</span>
                    {/* {inputView && <input type="text" value={inValue} onChange={inputChange} />} */}

                </div>
                <div className={manage.faqc_list_right}>
                    <button onClick={onClick}>수정</button>
                    <button onClick={deleteData}>삭제</button>
                </div>
            </div>}
            {inputView && <div className={manage.faqc_list_div}>
                <div className={manage.faqc_list_middle}>
                    <input type="text" value={inValue} onChange={inputChange} /></div>
                <div className={manage.faqc_list_right}>
                    <button onClick={updateData}>확인</button>
                    <button onClick={onCancel}>취소</button>
                </div></div>}
        </div>
    }

    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>FAQ 분류관리</span>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div className={manage.faqc_wrap}>
                        <div className={manage.faqc_inner}>
                            <div className={manage.faqc_insert}>
                                <input type="text" onChange={changeInsert} value={insertVale} />
                                <button onClick={onAdd}>추가</button>
                            </div>
                            <div className={manage.faqc_list}>
                                <div>
                                    {data && data.map((item) => {
                                        return <FaqCList data2={item} />
                                    })}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default FaqCaInner