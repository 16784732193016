import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/admin.module.css"
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";

import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { FaTaxi } from "react-icons/fa";
import { BiSolidPhoneCall, BiPhoneCall } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";


const FirstTaxiBox = () => {
    const navigate = useNavigate();
    const [insertVale, setInsertValue] = useState("");

    const [data, setData] = useState([]);

    const dispatch = useDispatch();





    const [tokenSwitch, setTokenSwitch] = useState(false);


    const getData = () => {


        let sendData = {

            where: "",
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/select_faqc",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    setData(result.messageinfo.message);
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        // getData();


    }, [])

    const user = useSelector(state => state.user);


    return <div className={manage.admin_box}>
        <div className={manage.admin_develop}>

        </div>
        <div className={manage.admin_box_inner}>
            <div className={manage.admin_box_top}>
                <h3>택시 관제</h3>
            </div>
            <div className={manage.admin_box_middle}>
                <div>
                    <ul>

                        <li>
                            <Link to="">
                                <div className={manage.box_middle_inner}>
                                    <div>

                                        <span>택시 운행 현황
                                        </span>
                                        <FaTaxi /></div>
                                    <div>
                                        <p>0</p>
                                    </div></div>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <div className={manage.box_middle_inner}>
                                    <div>

                                        <span>오늘의 콜 현황</span>
                                        <BiSolidPhoneCall /></div>
                                    <div>
                                        <p>0</p>
                                    </div></div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            < div className={manage.admin_box_bottom}>
                <ul>
                    <li> <Link to="">
                        <div><BiPhoneCall />
                            <span>
                                버스 콜 현황</span></div>
                    </Link>
                    </li>
                    <li><Link to=""><div>
                        <IoSettingsOutline />
                        <span>
                            관제 화면 열기</span></div>
                    </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div >
}

export default FirstTaxiBox