import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/busmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
const QnaInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const { idx } = useParams()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        }, {
            Header: "등록일자",
            accessor: "qna_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "문의내용",
            accessor: "qna_content",
            Cell: (row) => {
                let data = row.cell;
                let oriNo = row.cell.row.original.no;
                let thumbnail = row.cell.row.original.event_thumbnail;

                return (
                    <>

                        {data.value}

                    </>
                );
            },
        },
        {
            Header: "연락처",
            accessor: "user_mobile",
            Cell: ({ cell: { value } }) => <Phone value={value} />,
        },
        {
            Header: "답변처리",
            accessor: "qna_state",
            Cell: ({ cell: { value } }) => <>{value === 0 ? "미답변" : "답변"}</>
        },
        {
            Header: "답변일자",
            accessor: "qna_complete_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },


    ]);

    //accessor 스타일링
    const Phone = ({ value }) => {
        return <>{value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</>
    };
    const Date = ({ value }) => {

        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const DetailInfo = (oriNo) => {
        navigate(`/admin/event/${oriNo}`);
    };


    const data = [
        {}
    ]
    const cookies = new Cookies()


    const [tokenSwitch, setTokenSwitch] = useState(false);

    const getData = () => {


        let sendData = {
            startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: searchValue,
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_table",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    setRouteData(result.messageinfo.message);

                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        getData();


    }, [tokenSwitch])


    const [check, setCheck] = useState(0);

    const searchFun = () => {
        getData();
    }
    const resetFun = () => {
        setStartDate("")
        setEndDate("");
        setSearchValue("");


        let sendData = {
            startDate: "", endDate: moment().format("YYYY-MM-DD"), search: "",
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_table",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    setRouteData(result.messageinfo.message);

                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })


    }
    const onValue = useCallback((e) => {
        setSearchValue(e.target.value)
    }, [])
    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>1:1 문의 </span>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div className={manage.mange_search_wrap}>
                        <div className={manage.manage_search_box}>

                            <div className={manage.manage_search_inner}>
                                <div className={manage.manage_date}>
                                    <label>기간</label>
                                    <span className={manage.manage_datepicker}>
                                        <span>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                locale={ko}
                                                onChange={(date) => setStartDate(date)}
                                                maxDate={endDate}
                                                selected={startDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                placeholderText={"적용시작일"}
                                            />
                                        </span>
                                        <span className={manage.datepicker_ico}>
                                            <FaCalendar />
                                        </span>
                                    </span>
                                    <span>~</span>
                                    <span className={manage.manage_datepicker}>
                                        <span>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                locale={ko}
                                                onChange={(date) => setEndDate(date)}
                                                selected={endDate}
                                                maxDate={window.Date}
                                                startDate={endDate}
                                                endDate={endDate}
                                                placeholderText={"적용종료일"}
                                            />
                                        </span>
                                        <span className={manage.datepicker_ico}>
                                            <FaCalendar />

                                        </span>
                                    </span>

                                </div>
                                <div className={manage.manage_search}>
                                    <label>검색어</label>
                                    <input type="text" placeholder="휴대폰번호" value={searchValue} onChange={onValue} />
                                </div>
                            </div>
                            <div className={manage.manage_serach_button}>
                                <button onClick={resetFun}>초기화</button>
                                <button onClick={searchFun}>검색</button>
                            </div>
                        </div>

                    </div>
                    {routeData && <SearchTable columns={columns} data={routeData} type={"qna"} length={routeData.length} />}
                </div>
            </div>
        </div>
    </div >
}

export default QnaInner