import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/busmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
const StopInner = () => {


    const user = useSelector(state => state.user);


    useEffect(() => {




        // let sendData = {
        //     startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: searchValue,
        // };

        // CommonAxiosToken(
        //     process.env.REACT_APP_HOSTDONAME + "/api/admin/opercall_table",
        //     sendData,
        //     function (result) {
        //         console.log(result);
        //         if (result.messageinfo) {
        //             console.log(result.messageinfo)
        //             if (result.messageinfo.message.length > 0) {
        //                 setRouteData(result.messageinfo.message);
        //                 // setTokenSwitch(true);
        //             } else {
        //                 // setTokenSwitch(true);
        //             }
        //         } else {
        //             const options2 = {
        //                 method: "POST",
        //                 headers: {
        //                     Accept: "application/json",
        //                     "Content-Type": "application/json;charset=UTP-8",


        //                 },

        //             };
        //             if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
        //                 axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
        //                     .then((response) => {
        //                         if (response.data.message === "실패") {
        //                             dispatch(
        //                                 userSlice.actions.reset({})
        //                             )
        //                             navigate("/")
        //                         }
        //                         setTokenSwitch(true);

        //                     }).catch((error) => {
        //                         dispatch(
        //                             userSlice.actions.reset({})
        //                         )
        //                         navigate("/")
        //                     })
        //             }

        //         }

        //     })

    }, [])

    const password_test = /^[a-z0-9_]{4,10}$/
    const [changeP, setChangeP] = useState("");
    const changePassword = useCallback((e) => {
        setChangeP(e.target.value);
    }, [])
    const save_password = () => {
        console.log(changeP)
        if (!changeP || !password_test.test(changeP)) {
            window.alert("8~20자리 영문,숫자,특수문자 혼합하여 비밀번호를 입력해주세요.")
            return;

        }


        let sendData = {
            system_type: 1,
            system_value: changeP,
        }

        let record = {
            record_category: "정류장비밀번호",
            record_user: user.uniq,
            record_text: `정류장 비밀번호를 변경하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/change_stop_password",
            sendData,
            function (result) {
                AdminRecordAxios(record)
                window.alert("비밀번호 변경되었습니다.");
                setChangeP("");
                // navigate(`/appmg/qnamg`);
            })


    }
    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>정류장비밀번호 관리</span>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div className={manage.manage_default_box}>
                        <p>숫자,영문 4~10자리로 비밀번호를 생성하세요.
                        </p>
                        <div>
                            <input type="text" onChange={changePassword}
                                value={changeP} />
                            <button onClick={save_password}>저장</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default StopInner