
import { useEffect, useState } from "react";
import manage from "../../styles/moniter.module.css"
import { CommonAxios } from "../../module/CommonAxios";
import { AiOutlineRightCircle, AiOutlineLeftCircle, AiOutlineDownCircle } from "react-icons/ai";
import io from 'socket.io-client';
import { FaBus } from "react-icons/fa";

const MoniterItem = ({ data, busList }) => {
    const [waitCnt, setWaitCnt] = useState(0);
    const [raceCnt, setRaceCnt] = useState(0);
    const [routeData, setRouteData] = useState([]);
    // const [socket, setSocket] = useState();

    let cnt1 = 0;
    let not1 = 0;
    useEffect(() => {
        cnt1 = 0;
        not1 = 0;
    }, busList)

    const [lineData, LineData] = useState([]);
    useEffect(() => {

        // const newSocket = io.connect('http://yjview.kr:80');
        // const newSocket = io.connect('https://localhost:3000/')
        // setSocket(newSocket);

        let sendData = {
            no: data.no
        };



        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/moniter/select_bus_line",
            sendData,
            function (result) {
                if (result.messageinfo) {
                    let count = 0;
                    let arr = [];
                    let arr2 = [];



                    result.messageinfo.message.map((data3, index) => {
                        arr2.push(data3);
                        count++;

                        if (count % 10 === 0) {
                            // 2.arr 배열에 넣는다 (그 5개를)
                            arr.push(arr2);
                            // 기존 배열은 초기화
                            arr2 = [];


                        }
                        if (result.messageinfo.message.length === index + 1) {
                            arr.push(arr2);
                        }


                    })
                    LineData(arr)
                    // setSelectData(result.messageinfo.message);

                }
            })

    }, [data])

    // const [posits, setPosits] = useState();
    // const [socketArr, setSocketArr] = useState([]);

    // useEffect(() => {

    //     if (!socket) return;

    //     socket.on('connect', () => {
    //         console.log("연결완료!");


    //     });

    //     socket.on('position', ro => {
    //         // console.log(ro);
    //         setPosits(ro);
    //         console.log(ro);


    //         if (socketArr.length > 0) {
    //             let notData = socketArr.filter(item => item.list_no !== data.list_no);

    //             if (notData.length > 0) {

    //                 setSocketArr(socketArr => [...socketArr, data]);
    //                 // setCount(2);
    //             } else {
    //                 setSocketArr(socketArr.filter((item, i) => {
    //                     return item.list_no !== data.list_no;
    //                 }));
    //                 setSocketArr(socketArr => [...socketArr, data]);
    //                 // setCount(3);
    //             }

    //         } else {

    //             setSocketArr(socketArr => [...socketArr, data]);
    //             // setCount(1);
    //         }


    //     })

    // }, [socket])
    const [orderCount, setOrderCount] = useState(0);
    const RouteFlex = ({ data }) => {

        return (


            <>



                <div style={{ display: "flex", width: "100%", }}>

                    {data.map((data2, index, data) =>
                        <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "120px" }}>

                            <div style={{ position: "relative", width: '40px', justifyContent: "center" }}>
                                {busList.length > 0 && busList.map((item) => {

                                    return <div style={{
                                        position: "absolute", top: "-60px", zIndex: 1, display: "flex", flexDirection: "column", height: "40px", width: "40px",
                                        alignItems: "center",
                                        // background: "red",
                                        // justifyContent: "center"
                                    }}>
                                        {item.state !== 0 && item.order !== 0 && item.order === data2.rs_order && data2.rs_route_no === item.list_route_no &&
                                            <><span>{item.list_car_number}</span>
                                                <span style={{ fontSize: "30px", color: "#004EA2" }}><FaBus /></span></>
                                        }</div>

                                })}
                                {((index + 1) % 10 !== 0 || index === 0) && <div><AiOutlineRightCircle style={{ textAlign: "center", fontSize: "35px", color: "#004EA2" }} /></div>}
                                {(index + 1) % 10 === 0 && index !== 0 && <div><AiOutlineDownCircle style={{ textAlign: "center", fontSize: "35px", color: "#004EA2" }} /></div>}
                                {/* <div style={{ marginBottom: "10px", position: "relative" }}> */}
                                <div style={{ position: "absolute", top: 40, width: "80px", left: -40 }}>
                                    <span style={{ textAlign: "center", fontWeight: '700', width: "110px" }}>{data2.rs_name}</span></div>

                            </div>
                            {data.length - 1 !== index &&
                                ((index + 1) % 10 !== 0 || index === 0) &&
                                <div style={{ width: "90px", paddingTop: "15px", position: "relative" }}>
                                    <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>

                                    {/* {data2.rs_order >= orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>} */}
                                    {/* {data2.rs_order < orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "solid", borderColor: "#004EA2" }}></div>} */}
                                    <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
                                        {/* <div style={orderCount === data2.rs_order + 0.5 ? styles.BusIcondiv2 : styles.BusIconNot2}>
                                            <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} />
                                        </div> */}
                                    </div>
                                </div >
                            }
                            {(index + 1) % 10 === 0 && index !== 0 &&
                                <div style={{ width: "0", paddingTop: 15, position: "relative", }}>
                                    <div style={{ position: "absolute", top: 36, left: -20, zIndex: 1, borderWidth: 2, width: "0", height: "120px", borderStyle: "dashed", borderColor: "#FF8D00" }}></div>

                                    {/* {data2.rs_order >= orderCount && <div style={{ position: "absolute", top: 36, left: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>} */}
                                    {/* {data2.rs_order < orderCount && <div style={{ position: "absolute", top: 36, left: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "solid", borderColor: "#004EA2" }}></div>} */}
                                    <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
                                        {/* <div style={orderCount === data2.rs_order + 0.5 ? styles.BusIcondiv2 : styles.BusIconNot2}>
                                            <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} />
                                        </div> */}
                                    </div>
                                </div >}
                        </div>)}
                </div>
            </>
        )

        // return <View><span>홀</span></View>
    }

    const RouteFlexNext = ({ data }) => {

        return (


            <>



                <div style={{ display: "flex", width: "100%", flexDirection: "row-reverse" }}>
                    {data.map((data2, index, data) =>
                        <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "120px", flexDirection: "row-reverse" }}>
                            <div style={{ position: "relative", width: '40px', justifyContent: "center" }}>
                                {busList.length > 0 && busList.map((item) => {

                                    return <div style={{
                                        position: "absolute", top: "-60px", zIndex: 1, display: "flex", flexDirection: "column", height: "40px", width: "40px",
                                        alignItems: "center",
                                        // background: "red",
                                        // justifyContent: "center"
                                    }}>
                                        {item.state !== 0 && item.order !== 0 && item.order === data2.rs_order && data2.rs_route_no === item.list_route_no &&
                                            <><span>{item.list_car_number}</span>
                                                <span style={{ fontSize: "30px", color: "#004EA2" }}><FaBus /></span></>
                                        }</div>

                                })}
                                {((index + 1) % 10 !== 0 || index === 0) && <div><AiOutlineLeftCircle style={{ textAlign: "center", fontSize: "35px", color: "#004EA2" }} /></div>}
                                {(index + 1) % 10 === 0 && index !== 0 && <div><AiOutlineDownCircle style={{ textAlign: "center", fontSize: "35px", color: "#004EA2" }} /></div>}
                                {/* <div style={{ marginBottom: "10px" }}> */}
                                <div style={{ position: "absolute", top: 40, width: "80px", right: -20 }}>
                                    <span style={{ textAlign: "center", fontWeight: '700' }}>{data2.rs_name}</span></div>

                            </div>

                            {data.length - 1 !== index &&
                                ((index + 1) % 10 !== 0 || index === 0) &&
                                <div style={{ width: "90px", paddingTop: "15px", position: "relative" }}>
                                    <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>

                                    {/* {data2.rs_order >= orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>} */}
                                    {/* {data2.rs_order < orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "solid", borderColor: "#004EA2" }}></div>} */}
                                    <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
                                        {/* <div style={orderCount === data2.rs_order + 0.5 ? styles.BusIcondiv2 : styles.BusIconNot2}>
                                            <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} />
                                        </div> */}
                                    </div>
                                </div >
                            }
                            {(index + 1) % 10 === 0 && index !== 0 &&
                                <div style={{ width: "0", paddingTop: 15, position: "relative", }}>
                                    <div style={{ position: "absolute", top: 36, right: -20, zIndex: 1, borderWidth: 2, width: "0", height: "120px", borderStyle: "dashed", borderColor: "#FF8D00" }}></div>

                                    {/* {data2.rs_order >= orderCount && <div style={{ position: "absolute", top: 36, left: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>} */}
                                    {/* {data2.rs_order < orderCount && <div style={{ position: "absolute", top: 36, left: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "solid", borderColor: "#004EA2" }}></div>} */}
                                    <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
                                        {/* <div style={orderCount === data2.rs_order + 0.5 ? styles.BusIcondiv2 : styles.BusIconNot2}>
                                            <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} />
                                        </div> */}
                                    </div>
                                </div >}
                        </div>)}
                </div>
            </>
        )

        // return <View><span>홀</span></View>
    }
    // const RouteFlexNext = ({ data }: any) => {
    //   return (<View style={{ flexDirection: "row-reverse", }}>
    //     {data.map((data2: any, index: number) => <>
    //       <View style={{ position: "relative", width: 36, justifyContent: "center" }}>
    //         <View><AntDesign name="leftcircleo" size={36} style={{ textAlign: "center" }} /></View>
    //         <View><span>{data2.stop_name}</span></View>


    //       </View>

    //       {data2.rs_order % 5 !== 0 &&
    //         < View style={{ width: 150, paddingTop: 15 }}>
    //           <View style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></View>
    //         </View >}
    //     </>
    //     )
    //     }
    //   </View >)

    //   // return <View><span>홀</span></View>
    // }




    // const RouteFlexNext = ({ data }) => {

    //     return (


    //         <>
    //             <div style={{ flexDirection: "row-reverse" }}>
    //                 {data.map((data2, index, data) => <div key={index} style={{ flexDirection: "row-reverse" }}>
    //                     <div style={{ position: "relative", width: 36, justifyContent: "center" }}>


    //                         {((index + 1) % 5 !== 0 || index === 0) && data2.rs_stop_essential === 1 && data2.allocate_state === false && <div>
    //                             {/* <AntDesign name="leftcircleo" size={36} style={{ textAlign: "center" }} /> */}
    //                         </div>}
    //                         {(index + 1) % 5 === 0 && index !== 0 && data2.rs_stop_essential === 1 && data2.allocate_state === false && <div>
    //                             {/* <AntDesign name="downcircleo" size={36} style={{ textAlign: "center" }} /> */}
    //                         </div>}

    //                         {((index + 1) % 5 !== 0 || index === 0) && data2.rs_stop_essential === 1 && data2.allocate_state === true && <div style={{ backgroundColor: "#FF8C00", width: "100%", height: 36, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
    //                             <span style={{ fontWeight: '600' }}>경유</span></div>}
    //                         {(index + 1) % 5 === 0 && index !== 0 && data2.rs_stop_essential === 1 && data2.allocate_state === true && <div style={{ backgroundColor: "#FF8C00", width: "100%", height: 36, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center" }}>
    //                             <span style={{ fontWeight: '600' }}>경유</span></div>}




    //                         {data2.rs_stop_essential === 0 && <div style={{ backgroundColor: "#FF8C00", width: "100%", height: 36, borderRadius: 50, display: "flex", justifyContent: "center", alignItems: "center" }}><span style={{ fontWeight: '600' }}>경유</span></div>}

    //                         <div style={{ position: "absolute", top: 40, width: 80, left: -20, backgroundColor: "#EFF3FF" }}><span style={{ textAlign: "center", fontWeight: '700' }}>{data2.stop_name}</span></div>
    //                         {/* <div style={orderCount === data.rs_order ? manage.BusIcondiv : manage.BusIconNot}> */}
    //                         <div style={orderCount === data2.rs_order ? manage.BusIcondiv : manage.BusIconNot}>
    //                             {/* <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} /> */}
    //                         </div>


    //                     </div>
    //                     {data.length - 1 !== index &&
    //                         ((index + 1) % 5 !== 0 || index === 0) &&
    //                         <div style={{ width: 150, paddingTop: 15, position: "relative" }}>
    //                             {data2.rs_order >= orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>}
    //                             {data2.rs_order < orderCount && <div style={{ borderWidth: 2, width: '100%', height: 0, borderStyle: "solid", borderColor: "#004EA2" }}></div>}
    //                             <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
    //                                 <div style={orderCount === data2.rs_order + 0.5 ? manage.BusIcondiv2 : manage.BusIconNot2}>
    //                                     {/* <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} /> */}
    //                                 </div>
    //                             </div>
    //                         </div >}
    //                     {(index + 1) % 5 === 0 && index !== 0 &&
    //                         <div style={{ width: 0, paddingTop: 15, position: "relative" }}>
    //                             {data2.rs_order >= orderCount && <div style={{ position: "absolute", top: 36, right: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "dashed", borderColor: "#FF8D00" }}></div>}
    //                             {data2.rs_order < orderCount && <div style={{ position: "absolute", top: 36, right: -18, zIndex: -1, borderWidth: 2, width: 0, height: 80, borderStyle: "solid", borderColor: "#004EA2" }}></div>}
    //                             <div style={{ position: "absolute", left: 0, top: 0, justifyContent: "center", right: 0, zIndex: 3, width: 150, alignItems: "center" }}>
    //                                 <div style={orderCount === data2.rs_order + 0.5 ? manage.BusIcondiv2 : manage.BusIconNot2}>
    //                                     {/* <Icon2 name="bus" size={24} style={{ color: "#1B4D89" }} /> */}
    //                                 </div>
    //                             </div>
    //                         </div >}

    //                 </div>
    //                 )
    //                 }
    //             </div >
    //             <div style={{ height: 80, position: "relative" }}>
    //             </div></>)

    //     // return <div><span>홀</span></div>
    // }


    return <div className={manage.moniter_item_wrap}>
        <div className={manage.moniter_item_box}>
            <div className={manage.moniter_item_top}>
                <h3>{data.route_name}</h3>
                <ul>
                    <li>운행중  {busList.map((item, index) => {

                        if (item.state === 1 && data.no === item.list_route_no) {
                            cnt1 = cnt1 + 1;
                        }
                        if (index === busList.length - 1) {
                            return cnt1;
                        }

                    })}</li>
                    <li>대기중  {busList.map((item, index) => {

                        if (item.state === 0 && data.no === item.list_route_no) {
                            not1 = not1 + 1;
                        }
                        if (index === busList.length - 1) {
                            return not1;
                        }

                    })}</li>
                </ul>
                {/* <TopView race={raceCnt} wait={waitCnt} /> */}
            </div>
            <div className={manage.moniter_item_middle}>
                <div style={{ width: "1210px", paddingTop: "60px" }}>

                    {lineData && lineData.map((data, index) => {

                        if (index === 0 || index % 2 === 0) {
                            return <div key={index}><RouteFlex data={data} />
                            </div>


                        }

                        else {
                            return <div key={index}><RouteFlexNext data={data} />
                            </div>
                        }
                    })}

                </div>
            </div>
        </div>
    </div >
}

export default MoniterItem