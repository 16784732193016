
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAxios } from "../module/CommonAxios";
import line from "../styles/busmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";

import UserInfoWrap from "../components/manage/appmg/UserInfoWrap";
import CompanyInfoWrap from "../components/manage/busmg/CompanyInfoWrap";
const BusCompanyInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();
    const removecompany = () => {
        const result = window.confirm("해당 문의를 삭제하시겠습니까?");
        if (result) {

            let sendData = {
                no: idx,
            };
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/company_answer_remove",
                sendData,
                function (result) {

                    window.alert("삭제되었습니다.");
                    navigate(`/appmg/companymg`);
                }




            );
        }

    }
    return (
        <>

            <div className="common-wrap">
                <div className={line.company_wrap}>

                    <div className={line.company_box}>
                        <div className={line.company_content}>


                            <CompanyInfoWrap />
                        </div>  </div>
                </div>
            </div>
        </>
    )
}

export default BusCompanyInfo