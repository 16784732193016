import UserInner from "../components/manage/appmg/UserInner"
import Menu from "../components/manage/common/Menu"


const AppUserMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'user'} />
            <UserInner />
            {/* <StopInner /> */}
        </div>
    )
}

export default AppUserMg