import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/appmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
import { useAsyncDebounce } from "react-table";
import { textReplace } from "../../../lib/textReplace"
const FaqInfoWrap = ({ setTitle, title }) => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [selectData, setSelectData] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const [regDate, setRegDate] = useState("");
    // const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {


        let sendData = {
            no: ""
        };


        if (idx) {
            sendData.no = idx;
        }


        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/faq_detail_info",
            sendData,
            function (result) {
                if (result.messageinfo) {
                    setSelectData(result.messageinfo.message);
                    if (result.messageinfo.message_data2) {
                        setEvery(result.messageinfo.message_data2[0].faq_every === "1" ? true : false)
                        setRegDate(moment(result.messageinfo.message_data2[0].faq_date).format("YYYY-MM-DD HH:mm:ss"))
                        setSelectValue(result.messageinfo.message_data2[0].faq_category_no)
                        setTitle(result.messageinfo.message_data2[0].faq_title)
                        setContent(result.messageinfo.message_data2[0].faq_content)
                    }
                }
            })
    }, [])




    const changeTitle = useCallback((e) => {
        setTitle(e.target.value);
    }, [])

    const changeContent = useCallback((e) => {
        setContent(e.target.value);
    }, [])

    const btn_update = () => {


        let sendData = {
            no: idx,
            faq_category_no: selectValue,
            faq_every: every ? 1 : 0,
            faq_title: textReplace(title),
            faq_content: textReplace(content)




        }
        let record = {
            record_category: "FAQ",
            record_user: user.uniq,
            record_text: `''${title}''라는 FAQ를 수정하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/faq_update",
            sendData,
            function (result) {
                AdminRecordAxios(record);
                window.alert("FAQ가 수정되었습니다.");
                navigate(`/appmg/faqmg`);
            })

    }

    const btn_save = () => {

        let sendData = {
            faq_category_no: selectValue,
            faq_every: every ? 1 : 0,
            faq_title: textReplace(title),
            faq_content: textReplace(content)




        }
        let record = {
            record_category: "FAQ",
            record_user: user.uniq,
            record_text: `''${title}''라는 FAQ를 등록하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/faq_insert",
            sendData,
            function (result) {
                AdminRecordAxios(record);
                window.alert("FAQ가 등록되었습니다.")
                navigate(`/appmg/faqmg`);
            })


    }

    const chageSelect = (e) => {
        setSelectValue(e.target.value)
    }

    const [every, setEvery] = useState(false);
    const checkEvery = () => {
        setEvery(!every)
    }
    return (
        <>

            <div className={line.faq_info_wrap}>

                <div className={line.faq_info_box}>
                    <div className={line.faq_info_etc}>

                        <label><input type="checkbox" checked={every} onChange={checkEvery} /> 자주하는 질문으로 등록 </label>

                    </div>
                    <div className={line.faq_info_table}>


                        {idx !== '0' && <div className={line.faq_info_row}>
                            <span className={line.faq_info_span}>등록일자</span>
                            <div className={line.faq_info_input}>
                                <p>{regDate} </p>
                            </div>
                        </div>}
                        <div className={line.faq_info_row}>
                            <span className={line.faq_info_span}>분류</span>
                            <div className={line.faq_info_input}>
                                <select style={{
                                    border: "1px solid #a7a7a7", width: "200px", borderRadius: "5px"
                                }}
                                    onChange={chageSelect}
                                    value={selectValue ? selectValue : 0}
                                >
                                    <option value={0}>분류</option>
                                    {selectData && selectData.map((data) => {
                                        return <option value={data.no} key={data.no}>{data.faq_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>


                        <div className={line.faq_info_row}>
                            <span className={line.faq_info_span}>제목</span>
                            <div className={line.faq_info_input}>
                                <input type="text" onChange={changeTitle} value={title} />
                            </div>
                        </div>
                        <div className={line.faq_info_row}>
                            <span className={line.faq_info_span}>내용</span>
                            <div className={line.faq_info_input}>
                                <textarea onChange={changeContent} value={content}></textarea>
                            </div>
                        </div>


                    </div>

                    <div className={line.faq_save}>
                        {idx === '0' && <button onClick={btn_save}>저장</button>}
                        {idx !== '0' && <button onClick={btn_update}>수정</button>}
                    </div>

                </div>

            </div></>)
}

export default FaqInfoWrap