import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/appmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
import { useAsyncDebounce } from "react-table";
import NomalTable from "../table/NomalTable";
import Moment from "react-moment";
const UserInfoWrap = () => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [userDate, setUserDate] = useState("");
    const [userIdx, setUserIdx] = useState("");
    const [mobile, setMobile] = useState("");
    const [answer, setAnswer] = useState("");
    const [answerDate, setAnswerDate] = useState("");
    const [content, setContent] = useState("");
    const [gender, setGender] = useState(0);
    const [age, setAge] = useState("");
    const [agree, setAgree] = useState("");
    const [busData, setBusData] = useState([]);
    const columns = useMemo(() => [
        // {
        //     Header: "No",
        //     accessor: "ROWNUM",
        //     Cell: (row) => {
        //         let count = row.data.length;
        //         let { index } = row.cell.row;

        //         return (
        //             <>
        //                 <div>{count - index}</div>
        //             </>
        //         );
        //     },
        // },
        {
            Header: "콜 처리코드",
            accessor: "allocate_code",
            Cell: ({ cell: { value } }) => <>{value}</>
        }, {
            Header: "분류",
            accessor: "allocate_category",
            Cell: ({ cell: { value } }) => <>{value === 1 ? "O" : "X"}</>
        },
        {
            Header: "발신 일시",
            accessor: "allocae_get_on_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "승차 정류장",
            accessor: "allocate_get_on",
            Cell: ({ cell: { value } }) => <>{value}</>
        },
        {
            Header: "승차 예정 시간",
            accessor: "allocate_sent_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        }, {
            Header: "하차 정류장",
            accessor: "allocate_get_off",
            Cell: ({ cell: { value } }) => <>{value}</>
        },
        {
            Header: "처리 상태",
            accessor: "allocate_state",
            Cell: ({ cell: { value } }) => <>{value}</>
        },
    ]);
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    useEffect(() => {


        let sendData = {
            user_uniq: idx
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/user_detail_info",
            sendData,
            function (result) {
                if (result.messageinfo) {
                    setUserDate(moment(result.messageinfo.message[0].user_reg_date).format("YYYY-MM-DD HH:mm:ss"));
                    setMobile(result.messageinfo.message[0].user_mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
                    setUserIdx(result.messageinfo.message[0].user_uniq);
                    setAnswerDate(result.messageinfo.message[0].user_complete_date);
                    // setAnswerDate(moment(result.messageinfo.message[0].user_complete_date).format("YYYY-MM-DD HH:mm:ss"));
                    setContent(result.messageinfo.message[0].user_content);
                    setGender(result.messageinfo.message[0].user_gender);
                    setAge(result.messageinfo.message[0].user_age);
                    setAgree(result.messageinfo.message[0].user_agree);
                    setBusData(result.messageinfo.message_data2);
                    // console.log(Number(result.messageinfo.message[0].user_age))
                }
            })
    }, [])




    const changeNumber = useCallback((e) => {
        setAnswer(e.target.value)
    }, [])



    const btn_save = () => {

        let sendData = {
            no: idx,
            user_answer: answer,
            user_complete_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/user_answer_update",
            sendData,
            function (result) {
                window.alert("답변이 등록되었습니다.")

            })


    }

    const password_test = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$~!%*#?&])[A-Za-z\d@$~!%*#?&]{8,20}$/
    const [changeP, setChangeP] = useState("");
    const changePassword = useCallback((e) => {
        setChangeP(e.target.value);
    }, [])
    const save_password = () => {
        console.log(changeP)
        if (!changeP || !password_test.test(changeP)) {
            window.alert("8~20자리 영문,숫자,특수문자 혼합하여 비밀번호를 입력해주세요.")
            return;

        }
        let sendData = {
            user_uniq: idx,
            password: changeP,



        }
        let record = {
            record_category: "회원정보",
            record_user: user.uniq,
            record_text: `''${mobile}''님의 비밀번호를 변경하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/user_detail_password",
            sendData,
            function (result) {
                AdminRecordAxios(record)
                window.alert("비밀번호 변경되었습니다.");
                setChangeP("");
                // navigate(`/appmg/qnamg`);
            })


    }
    return (
        <>

            <div className={line.user_info_wrap}>

                <div className={line.user_info_box}>
                    <div className={line.user_info_etc}>
                        <p>{mobile}</p>
                        <p><span style={{ marginRight: "3px" }}>{gender === 1 ? "남" : "여"}</span><span> {age === 0 ? "-" : age} 세</span></p>

                    </div>
                    <div className={line.user_info_table}>


                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>가입일시</span>
                            <div className={line.user_info_input}>
                                <p>{userDate}</p>
                            </div>
                        </div>
                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>IDX</span>
                            <div className={line.user_info_input}>
                                <p>{userIdx}</p>
                            </div>
                        </div>
                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>휴대폰</span>
                            <div className={line.user_info_input}>
                                <p>{mobile}</p>
                            </div>
                        </div>


                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>비밀번호</span>
                            <div className={line.user_info_input}>
                                <input type="password" onChange={changePassword}
                                    value={changeP} /> <button onClick={save_password}>변경</button>
                            </div>
                        </div>
                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>성별</span>
                            <div className={line.user_info_input}>
                                <p>{gender === 1 ? "남" : "여"}</p>
                            </div>
                        </div>
                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>나이</span>
                            <div className={line.user_info_input}>
                                <p>{age === 0 ? "-" : age}</p>
                            </div>
                        </div>
                        <div className={line.user_info_row}>
                            <span className={line.user_info_span}>마케팅 수신동의</span>
                            <div className={line.user_info_input}>
                                <p>{agree === 0 ? "미동의" : "동의"}</p>
                            </div>
                        </div>
                    </div>



                </div>
                <div className={line.user_info_box2}>
                    <p style={{ padding: "4px 0", fontWeight: "700" }}>버스이용내역</p>
                    {busData && <NomalTable columns={columns} data={busData} type={"userBus"} />}


                    {/* <p>택시이용내역</p> */}
                </div>

            </div></>)
}

export default UserInfoWrap