import Menu from "../components/manage/common/Menu"
import RouteInner from "../components/manage/busmg/RouteInner"


const BusRouteMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'bus'} />

            <RouteInner />
        </div>
    )
}

export default BusRouteMg