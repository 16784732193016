import style from "../../../styles/control.module.css"
import { FaBus } from "react-icons/fa";
import { PiCircleFill, PiCircle } from "react-icons/pi";
import controlSlice from "../../../slices/control";
import { useDispatch, useSelector } from "react-redux";

const ControlLeftList = ({ setBusPop, setStopPop, busList, stopList, check, positArr, route }) => {
    const dispatch = useDispatch();
    const ItemList = ({ item }) => {
        const control = useSelector(state => state.control);

        const checkData = () => {
            let cnt = 0;
            if (busList && control.busList.length > 0) {


                control.busList && control.busList.map((data) => {
                    if (data.list_no === item.no) {
                        if (data.state === 1) {
                            cnt++;
                        }
                    }

                })
            }
            if (cnt > 0) {
                return "운행중"
            }
            else {
                return "운행대기중 "
            }


        }

        const checkData1 = () => {
            let aa = "";
            if (control.busList.length > 0) {


                control.busList && control.busList.map((data) => {
                    if (data.list_no === item.no) {
                        aa = data.nextStop;
                    }

                })
            }
            return aa;


        }
        const clickData = (un, carNo, routeNo) => {
            dispatch(
                controlSlice.actions.setBusPop2({
                    carNo: carNo,
                    routeNo: routeNo,
                    carUniq: un

                })
            )
            setBusPop(true);


        }
        return <div className={style.left_item_box} onClick={() => clickData(item.no, item.list_car_number, item.list_route_no)}>
            <div>
                <FaBus />
            </div>
            <div className={style.item_bus_name}>
                <span>{item.bus_number}</span>
            </div>
            <div>
                <div style={{ display: "flex" }}>
                    <p style={{ width: "70px" }}>상태</p>
                    <p style={{ flex: "1" }}>{checkData()}</p>
                </div>
                <div style={{ display: "flex" }}>
                    <p style={{ width: "70px" }}>다음 정류장</p>
                    <p style={{ flex: "1" }}>{checkData1()}</p>
                </div>

            </div>

        </div>
    }
    const ItemList2 = ({ item }) => {
        const clickData = (stopNo) => {
            dispatch(
                controlSlice.actions.setstopPop2({
                    stopNo: stopNo,


                })
            )
            setStopPop(true);


        }
        return <div className={style.left_item_box2} onClick={() => clickData(item.stop_number)}>
            <div>
                <PiCircleFill />
            </div>

            <div>


                <p >{item.stop_name} {item.stop_number}</p>


            </div>

            <div>


                <p >{item.stop_direction} </p>


            </div>

        </div>
    }
    const ItemList3 = ({ item }) => {
        const clickData = (stopNo) => {
            dispatch(
                controlSlice.actions.setstopPop2({
                    stopNo: stopNo,


                })
            )


        }
        return <div className={style.left_item_box2} onClick={() => clickData(item.stop_number)}>
            <div>
                {item.rs_stop_essential === 1 ?
                    <PiCircleFill /> : <PiCircle />}
            </div>

            <div>


                <p >{item.stop_name} {item.stop_number}</p>


            </div>

            <div>


                <p >{item.stop_direction} </p>


            </div>

        </div>
    }
    return (
        <div className={style.left_list_inner}>
            {check === 1 &&
                <div>
                    <div style={{ padding: "5px 20px", borderBottom: "1px solid #d7d7d7" }}>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>총{busList.length}건</span>
                    </div>
                    {busList.map((item) => {
                        return <ItemList item={item} />
                    })}
                </div>
            }
            {check === 2 &&
                <div>
                    <div style={{ padding: "5px 20px", borderBottom: "1px solid #d7d7d7" }}>
                        <span style={{ fontSize: "14px", fontWeight: "700" }}>총{stopList.length}건</span>
                    </div>

                    {route === 0 && stopList.map((item) => {
                        return <ItemList2 item={item} />
                    })}
                    {route !== 0 && stopList.map((item) => {
                        return <ItemList3 item={item} />
                    })}
                </div>
            }
        </div>
    )
}

export default ControlLeftList