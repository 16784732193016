import { useEffect, useMemo, useState } from "react";
import manage from "../../../styles/busmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
const CallInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const { idx } = useParams()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        },

        {
            Header: "아이디",
            accessor: "user_id",

        },
        {
            Header: "담당자 이름",
            accessor: "user_name",
        },
        {
            Header: "담당자 연락처",
            accessor: "user_mobile",
            Cell: ({ cell: { value } }) => <Phone value={value} />,

        }, {
            Header: "상태",
            accessor: "user_status",
            Cell: ({ cell: { value } }) => <>{value === 1 ? "정상" : value === 2 ? "잠김" : "만료"}</>
        }, {
            Header: "등록일",
            accessor: "user_reg_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },

    ]);

    //accessor 스타일링
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const Phone = ({ value }) => {
        return <>{value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</>
    };
    const DetailInfo = (oriNo) => {
        navigate(`/admin/event/${oriNo}`);
    };


    const data = [
        {}
    ]
    const cookies = new Cookies()


    const [tokenSwitch, setTokenSwitch] = useState(false);
    useEffect(() => {




        let sendData = {
            startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: searchValue,
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/opercall_table",
            sendData,
            function (result) {
                console.log(result);
                if (result.messageinfo) {
                    console.log(result.messageinfo)
                    if (result.messageinfo.message.length > 0) {
                        setRouteData(result.messageinfo.message);
                        // setTokenSwitch(true);
                    } else {
                        // setTokenSwitch(true);
                    }
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })

    }, [tokenSwitch])


    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>콜센터담당자관리</span>
                <button onClick={() => { navigate('/operatemg/call/callInfo/0') }}>등록하기</button>
            </div>
            <div className={manage.manage_middle}>
                <div>

                    {routeData && <NomalTable columns={columns} data={routeData} type={"operCall"} />}
                </div>
            </div>
        </div>
    </div >
}

export default CallInner