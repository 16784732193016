import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/appmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment"
const QnaInfoWrap = ({ setMobile, mobile }) => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [qnaDate, setQnaDate] = useState("");

    // const [mobile, setMobile] = useState("");
    const [answer, setAnswer] = useState("");
    const [answerDate, setAnswerDate] = useState("");
    const [content, setContent] = useState("");
    const [gender, setGender] = useState(0);
    const [age, setAge] = useState("");

    useEffect(() => {


        let sendData = {
            no: idx
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_detail_info",
            sendData,
            function (result) {
                if (result.messageinfo) {
                    setQnaDate(moment(result.messageinfo.message[0].qna_date).format("YYYY-MM-DD HH:mm:ss"));
                    setMobile(result.messageinfo.message[0].user_mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`));
                    setAnswer(result.messageinfo.message[0].qna_answer);
                    setAnswerDate(result.messageinfo.message[0].qna_complete_date);
                    // setAnswerDate(moment(result.messageinfo.message[0].qna_complete_date).format("YYYY-MM-DD HH:mm:ss"));
                    setContent(result.messageinfo.message[0].qna_content);
                    setGender(result.messageinfo.message[0].user_gender);
                    setAge(new Date().getFullYear() - result.messageinfo.message[0].user_year + 1);

                    // console.log(Number(result.messageinfo.message[0].user_age))
                }
            })
    }, [])




    const changeNumber = useCallback((e) => {
        setAnswer(e.target.value)
    }, [])



    const btn_save = () => {




        let sendData = {
            no: idx,
            qna_answer: answer,
            qna_complete_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }
        let record = {
            record_category: "qna",
            record_user: user.uniq,
            record_text: `''${mobile}''님의 질문에 답변을 등록하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_answer_update",
            sendData,
            function (result) {
                AdminRecordAxios(record)
                window.alert("답변이 등록되었습니다.")
                navigate(`/appmg/qnamg`);
            })


    }


    return (
        <>

            <div className={line.qna_info_wrap}>

                <div className={line.qna_info_box}>
                    <div className={line.qna_info_etc}>
                        <p>{mobile}</p>
                        <p><span>{gender === 1 ? "남" : "여"}</span><span>{age}세</span></p>

                    </div>
                    <div className={line.qna_info_table}>


                        <div className={line.qna_info_row}>
                            <span className={line.qna_info_span}>등록일</span>
                            <div className={line.qna_info_input}>
                                <p>{qnaDate}</p>
                            </div>
                        </div>
                        <div className={line.qna_info_row}>
                            <span className={line.qna_info_span}>휴대폰</span>
                            <div className={line.qna_info_input}>
                                <p>{mobile}</p>
                            </div>
                        </div>


                        <div className={line.qna_info_row}>
                            <span className={line.qna_info_span}>문의내용</span>
                            <div className={line.qna_info_input} dangerouslySetInnerHTML={{
                                __html: content,
                            }}>

                            </div>
                        </div>
                        <div className={line.qna_info_row}>
                            <span className={line.qna_info_span}>답변</span>
                            <div className={line.qna_info_input}>
                                <textarea value={answer} onChange={changeNumber}> </textarea>
                            </div>
                        </div>
                        <div className={line.qna_info_row}>
                            <span className={line.qna_info_span}>답변완료일</span>
                            <div className={line.qna_info_input}>
                                {answerDate ? moment(answerDate).format("YYYY-MM-DD HH:mm:ss") : ""}
                            </div>
                        </div>

                    </div>

                    <div className={line.qna_save}>
                        <button onClick={btn_save}>답변완료</button>
                    </div>

                </div>

            </div></>)
}

export default QnaInfoWrap