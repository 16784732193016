import StopInner from "../components/manage/busmg/StopInner"
import Menu from "../components/manage/common/Menu"


const BusStopMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'bus'} />
            <StopInner />
        </div>
    )
}

export default BusStopMg