
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAxios } from "../module/CommonAxios";
import MapWrap from "../components/manage/busmg/RouteMapWrap";
import line from "../styles/busmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
const BusRouteLine = () => {
    const navigate = useNavigate();
    const { idx } = useParams();


    // useEffect(() => {



    //     // api 연동
    //     let sendData = {};
    //     CommonAxios(
    //         process.env.REACT_APP_HOSTDONAME + "/api/source",
    //         sendData,
    //         function (result) {
    //             if (result.messageinfo.state == "ok") {
    //             } else {

    //             }
    //         }
    //     );
    // }, [])
    return (
        <>
            {/* <div id="container"> */}
            <div className="common-wrap">
                <div className={line.line_wrap}>
                    {/* <div className={line.line_left_menu}>

                        </div> */}
                    <div className={line.line_box}>
                        <div className={line.line_content}>
                            <div className={line.line_inner}>
                                <FaArrowLeftLong />
                                <ul className={line.line_menu}>
                                    <li className={line.line_menu_li}><Link to={`/busmg/routemg/routeInfo/${idx}`}>노선정보</Link></li>
                                    <li className={`${line.line_menu_li} ${line.line_menu_li_color}`}><Link to={`/busmg/routemg/busline/${idx}`}>노선경로</Link></li>
                                </ul>
                            </div>
                            <MapWrap idx={idx} /></div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default BusRouteLine