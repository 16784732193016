import FaqCaInner from "../components/manage/appmg/FaqCaInner"
import FaqInner from "../components/manage/appmg/FaqInner"
import Menu from "../components/manage/common/Menu"


const AppFaqCaMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'user'} />
            <FaqCaInner />

        </div>
    )
}

export default AppFaqCaMg