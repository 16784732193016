import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import styles from "./../styles/login.module.css"
import logo from "./../assets/logo/logo_2.svg"
import io from 'socket.io-client';
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from '../module/CommonAxios';
import userSlice from '../slices/user';
import { setCookie } from '../module/Cookie';
import { Cookies } from "react-cookie";

import axios from "axios"
import moment from 'moment';
const LoginPage = () => {
    const user = useSelector(state => state.user);
    const cookies = new Cookies();
    // const [cookies, setCookie] = useCookies(['name']);
    const loginRef = useRef([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [phoneView, setPhoneView] = useState(false);
    useEffect(() => {

        if (user.id) {
            navigate("/main")
        }
    }, [])

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onLogin(loginRef.current[0].value, loginRef.current[1].value);
        }
    };

    const idChange = useCallback((e) => {
        console.log(e.target.value);
        setId(e.target.value)

    }, [])
    const passwordChange = useCallback((e) => {
        setPassword(e.target.value)

    }, [])


    const [logCount, setLogCount] = useState(0);
    const [errView, setErrView] = useState(false);
    const [errView1, setErrView1] = useState(false);
    const onLogin = () => {
        // cookies.set("tokenff", `ddd`)
        if (id === "") {
            Swal.fire({
                title: "아이디를 입력해주세요",
                icon: "error",
                confirmButtonColor: "#d33",
                confirmButtonText: "확인",
                allowOutsideClick: false,
            });
        } else if (password === "") {
            Swal.fire({
                title: "비밀번호를 입력해주세요",
                icon: "error",
                confirmButtonColor: "#d33",
                confirmButtonText: "확인",
                allowOutsideClick: false,
            });
        } else {
            let sendData = {
                id: id,
                password: password,
            };

            let record = {
                record_category: "로그인",
                record_user: "",
                record_text: `로그인하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            console.log(process.env.REACT_APP_HOSTDONAME)
            CommonAxiosToken(
                process.env.REACT_APP_HOSTDONAME + "/api/call/login",
                sendData,
                function (result) {
                    if (result.message == "성공") {
                        console.log(result.user)
                        if (result.data.type === type) {

                            AdminRecordAxios(record);
                            dispatch(
                                userSlice.actions.setUser({
                                    id: result.data.id,
                                    uniq: result.data.uniq,
                                    type: result.data.type,
                                })
                            )


                            if (result.data.type === "1") {
                                navigate("/main")
                            } else if (result.data.type === "2") {
                                navigate("/admin")
                            } else {
                                navigate("/moniter")
                            }


                            // setPhoneView(true);
                        }
                        // setPhoneView(true);
                        // setCookie('name', result.data.accessToken, { httpOnly: true });
                        // cookies.set("tokenff", `${result.data.accessToken}`, { httpOnly: true })
                        // dispatch(
                        //     userSlice.actions.setUser({
                        //         id: result.data.id,
                        //         uniq: result.data.uniq,
                        //     })
                        // )

                        // console.log(result.data.accessToken)





                        // setCookie("token", `JWT ${result.data.accessToken}`, {
                        //     expires: '5m'

                        // });


                        // navigate("/main")

                    } else {
                        if (result.message === "로그인 정보를 다시 확인하세요.") {

                            if (logCount === 3) {
                                return CommonAxiosToken(
                                    process.env.REACT_APP_HOSTDONAME + "/api/login/update_admin_user_status",
                                    sendData,
                                    function (result) {
                                        {
                                            setErrView(false);
                                            setErrView1(true);
                                            setLogCount(0)
                                        }
                                    })
                            }
                            setErrView(true);
                            setErrView1(false);
                            setLogCount(logCount + 1)
                        } else {
                            setErrView(false); setErrView1(true);
                        }





                    }
                }
            );

        }

    };

    const [mobileGetNum, setMobileGetNum] = useState(""); //핸드폰 인증 번호
    const [pass, setPass] = useState(false);
    const sendSms = (e) => {
        e.preventDefault();
        if (
            phoneData === "" ||
            !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(phoneData)
        ) {
            setOneError(false);
            setTwoError(true);
            return;
        } // e.prev

        var sendData = {
            mobileNum: phoneData,
        };
        // setMobileGetNum("123456");
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
            sendData,
            function (result) {
                // console.log(result);
                if (result.messageinfo.state == "ok") {
                    console.log(result.messageinfo.message)
                    setMobileGetNum(result.messageinfo.message);
                    setPass(true);
                } else {
                }
            }
        );
    }
    const [oneError, setOneError] = useState(true); //유효하지 않는 번호 (인증 요청 눌렀을 시)
    const [twoError, setTwoError] = useState(true); //인증 요청을 안하고 저장 눌렀을 시
    const [phoneData, setPhoneData] = useState("");
    const [mobileConfirmTest, setMobileConfirmTest] = useState(null); // 인증번호 일치 여부
    const [threeError, setThreeError] = useState(true); //인층 확인 안하고 저장

    const idChange2 = useCallback((e) => {
        setPhoneData(e.target.value)

    }, [])
    const [numData, setNumdata] = useState("");
    const idChange3 = useCallback((e) => {
        setNumdata(e.target.value);
    }, [])


    const mobileConfirm = () => {
        if (numData == mobileGetNum) {
            setMobileConfirmTest(true);
            setThreeError(true);
        } else {
            setMobileConfirmTest(false);
            setThreeError(true);
        }

    }

    const onLogin1 = () => {
        if (!mobileConfirmTest) return;
        if (!phoneData) return;
        let sendData = {
            user_id: id,
            user_mobile: phoneData,
        };
        let record = {
            record_category: "로그인",
            record_user: "",
            record_text: `로그인하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/call/loginNext",
            sendData,
            function (result) {
                if (result.message == "성공") {
                    record.record_user = result.data.uniq;


                    AdminRecordAxios(record);
                    dispatch(
                        userSlice.actions.setUser({
                            id: result.data.id,
                            uniq: result.data.uniq,
                            type: result.data.type,
                        })
                    )


                    if (result.data.type === "1") {
                        navigate("/main")
                    } else if (result.data.type === "2") {
                        navigate("/admin")
                    } else {
                        navigate("/moniter")
                    }

                }
            })
    }

    const [type, setType] = useState(1);
    const changeType = (data) => {

        setType(data);
    }
    return (
        <div className={styles.login_container}>
            <div className={styles.login_wrap}>
                {!phoneView ? <>
                    <div className={styles.login_area_box}>
                        <img src={logo} />
                        <h3 className={styles.login_area_title}>괴산콜버스</h3>
                        <div className={styles.login_inp_area}>
                            <input type="text" className={styles.login_id_inp}
                                value={id}
                                onChange={idChange}
                                ref={(elem) => (loginRef.current[0] = elem)}
                                placeholder="아이디를 입력하세요." />
                            <input type="password" className={styles.login_pw_inp}
                                placeholder="비밀번호를 입력하세요."
                                ref={(elem) => (loginRef.current[1] = elem)}
                                autoComplete="off"
                                onChange={passwordChange}
                                value={password}
                                onKeyPress={handleKeyPress}
                            />
                            <div style={{ display: "flex", marginBottom: "10px" }}>
                                <label style={{ cursor: "pointer", display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <input name="kind" onChange={() => changeType(1)} type="radio" style={{ marginBottom: 0, width: "40px" }} checked={type === 1 ? true : false} />콜센터</label>
                                <label style={{ cursor: "pointer", display: "flex", flex: 1, alignItems: "center" }}>
                                    <input name="kind" onChange={() => changeType(2)} type="radio" style={{ marginBottom: 0, width: "40px" }} checked={type === 2 ? true : false} />관제실</label>
                                <label style={{ cursor: "pointer", display: "flex", flex: 1, alignItems: "center" }}>
                                    <input name="kind" onChange={() => changeType(3)} type="radio" style={{ marginBottom: 0, width: "40px" }} checked={type === 3 ? true : false} />모니터링</label>
                            </div>
                            {errView && <p style={{ marginBottom: "15px", textAlign: "center", fontSize: "12px", color: "red" }}>로그인 정보를 다시 확인하세요. ({logCount}/3)</p>
                            }
                            {errView1 && <p style={{ marginBottom: "15px", textAlign: "center", fontSize: "12px", color: "red" }}>잠긴 계정입니다.</p>
                            }

                            <button className={styles.login_btn}
                                onClick={() =>


                                    onLogin()

                                }
                            >
                                로그인하기
                                {/* 로그인 후 핸드폰 인증하기 */}
                            </button>
                        </div>
                    </div></>
                    :
                    <>
                        <div className={styles.login_area_box} style={{ width: "450px" }}>
                            <img src={logo} />
                            <h3 className={styles.login_area_title}>핸드폰 인증</h3>
                            <div className={styles.login_inp_area} style={{ width: "360px" }}>
                                <div style={{ display: "flex" }}>
                                    <input type="text"

                                        className={styles.login_id_inp}
                                        value={phoneData}
                                        onChange={idChange2}
                                        style={{ flex: "1", height: "40px", marginBottom: "7px" }}
                                        // ref={(elem) => (loginRef.current[0] = elem)}
                                        placeholder="등록되있는 핸드폰 번호를 입력해주세요." />
                                    <button style={{ display: "inline-block", width: "100px", marginLeft: "5px", background: "#adadad", marginBottom: "7px" }} onClick={sendSms}>인증 요청</button>
                                </div>

                                {twoError ? null : (
                                    <p className={styles.error_modal_p}>
                                        휴대폰 인증을 진행하지 않았습니다.
                                    </p>
                                )}
                                {pass && <div style={{ display: "flex" }}>
                                    <input type="text"

                                        className={styles.login_id_inp}
                                        value={numData}
                                        onChange={idChange3}
                                        style={{ flex: "1", height: "40px", marginBottom: "7px" }}
                                        // ref={(elem) => (loginRef.current[0] = elem)}
                                        placeholder="인증번호를 입력하세요." />
                                    <button style={{ display: "inline-block", width: "100px", marginLeft: "5px", background: "#adadad", marginBottom: "7px" }} onClick={mobileConfirm}>확인</button>
                                </div>}
                                {oneError ? null : (
                                    <p style={{ fontSize: "14px", paddingLeft: "5px", textAlign: "center", marginBottom: "7px" }}>
                                        유효하지 않는 번호입니다.
                                    </p>
                                )}

                                {mobileConfirmTest ===
                                    null ? null : mobileConfirmTest ? (
                                        <p style={{ fontSize: "14px", paddingLeft: "5px", textAlign: "center", marginBottom: "7px" }}>
                                            인증번호가 일치합니다.
                                        </p>
                                    ) : (
                                    <p style={{ fontSize: "14px", paddingLeft: "5px", textAlign: "center", marginBottom: "7px" }}>인증번호가 일치하지 않습니다.
                                    </p>
                                )}
                                {threeError ? null : (
                                    <p style={{ fontSize: "14px", paddingLeft: "5px", textAlign: "center", marginBottom: "7px" }}>인증번호를 입력하지 않았습니다.
                                    </p>
                                )}
                                {/* <input type="password" className={styles.login_pw_inp}
        placeholder="비밀번호를 입력하세요."
        autoComplete="off"
        onChange={passwordChange}
        value={password}
        onKeyPress={handleKeyPress}
    /> */}
                                {/* {errView && <p style={{ marginBottom: "15px", textAlign: "center", fontSize: "12px", color: "red" }}>로그인 정보를 다시 확인하세요. ({logCount}/3)</p>
    }
    {errView1 && <p style={{ marginBottom: "15px", textAlign: "center", fontSize: "12px", color: "red" }}>잠긴 계정입니다.</p>
    } */}
                                <button className={styles.login_btn}
                                    onClick={() =>


                                        onLogin1()

                                    }
                                >
                                    로그인
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div >
    )
}

export default LoginPage;