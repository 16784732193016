import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Scrollbars } from 'react-custom-scrollbars';
import { FiPhoneCall } from "react-icons/fi";
import { IoMdBus } from "react-icons/io";
import * as CommonAxios from "./../CommonAxios";
import moment from "moment";
import styles from "./../../styles/modal.module.css"
import Swal from "sweetalert2";

const WorkModal = ({ value, routeArray, nameArray, onClose, setSocket, socket }) => {

    const [codeNo, setCodeNo] = useState();         // 발신번호
    const [sentDate, setSentDate] = useState();     // 발신일시
    const [rcvDate, setRcvDate] = useState();       // 수신일시
    const [sbmDate, setSbmDate] = useState();       // 처리일시

    const [routeName, setRouteName] = useState();   // 노선명
    const [onName, setOnName] = useState();         // 승차정거장명
    const [offName, setOffName] = useState();       // 하차정거장명
    const [allocateResult, setAllocateResult] = useState();

    const [check, setCheck] = useState(["접수취소"]);            // 체크
    const [reason, setReason] = useState("");                   // 메모


    useEffect(() => {
        if (value && routeArray && nameArray) {

            setCodeNo(value.allocate_code);
            setSentDate(moment(value.allocate_sent_date).format("YYYY-MM-DD HH:mm:ss"));
            setRcvDate(moment(value.allocate_sent_date).format("YYYY-MM-DD HH:mm:ss"));
            setSbmDate(moment(value.allocate_complete_date).format("YYYY-MM-DD HH:mm:ss"));

            let routeArr = nameArray.filter((a) => a.no == value.list_route_no);
            if (routeArr.length > 0) {
                setRouteName(routeArr[0].route_name);
            }

            let onArr = routeArray.filter((a) => a.stop_number == value.allocate_get_on);
            if (onArr.length > 0) {
                setOnName(onArr[0].stop_name);
            }

            let offArr = routeArray.filter((a) => a.stop_number == value.allocate_get_off);
            if (offArr.length > 0) {
                setOffName(offArr[0].stop_name);
            }

            console.log(value);
            console.log(routeArray);
            console.log(nameArray);

        }
    }, [])

    const handleChangeCheck = (item) => {
        setCheck([item]);
    };

    const handleChangeReason = (item) => {
        setReason(item);
    };

    const submitBtn = (e) => {
        e.preventDefault();
        console.log(value);
        let sendSocket = {
            user_uniq: value.allocate_user_no, // 관리자 고유번호 넣을 것,
            onStop: value.allocate_get_on,
            offStop: value.allocate_get_off,
            callCode: value.allocate_code,
            busNum: value.allocate_bus_no,//배차 버스 번호
            status: "active",
            recordId: value.allocate_record_id,//버스 주행 고유번호

        }
        let ar = [];
        ar.push({ busNum: value.allocate_bus_no }); //bus_list 의 고유번호

        // ar.push(sendSocket);
        // socket.emit('userTodriverCancel', arr);
        //   let sendSocket = {
        //     `user_uniq`: user.uniq,
        //     onStop: allocate.startNum,
        //     offStop: allocate.endNum,
        //     callCode: allocate.callCode,
        //     busNum: allocate.busName,
        //     status: 'user',
        //     recordId: allocate.recordId
        //   }
        //   let ar = [];
        //   ar.push({ list_no: allocate.busNo });
        ar.push(sendSocket);

        //     if (res.isConfirmed) {
        Swal.fire({
            title: "취소 접수중",
            text: "취소 대기 중입니다. 잠시만 기다려주세요.",
            icon: "warning",
            showConfirmButton: false,
            allowOutsideClick: false

            // confirmButtonText: "확인",
            // confirmButtonColor: "#FF0000",
        });

        socket.emit('userTodriverCancel', ar)
    }


    const cancelBtn = () => {
        onClose();
    }

    return (
        <div className={styles.modal_div2}>
            <div className={styles.modal_title} style={{ fontWeight: "bold" }}>업무상세</div>
            <div className={`${styles.modal_contents} ${styles.modal_btm_line}`}>
                <div className={styles.modal_subtitle}>
                    <FiPhoneCall style={{ marginRight: "10px" }} />대표번호 콜
                </div>
                <div className={styles.modal_subdiv}>
                    <div>발신번호</div>
                    <div>{codeNo}</div>
                </div>
                <div className={styles.modal_subdiv}>
                    <div>발신일시</div>
                    <div>{sentDate}</div>
                </div>
                <div className={styles.modal_subdiv}>
                    <div>수신일시</div>
                    <div>{rcvDate}</div>
                </div>
                <div className={styles.modal_subdiv}>
                    <div>처리일시</div>
                    <div>{sbmDate}</div>
                </div>
            </div>
            <div className={`${styles.modal_contents} ${styles.modal_btm_line}`}>
                <div className={styles.modal_subtitle}>
                    <IoMdBus style={{ marginRight: "10px" }} />
                    {value.allocate_state === 1 && <span>배차전</span>}
                    {value.allocate_state === 2 && <span>배차 완료</span>}
                    {value.allocate_state === 3 && <span>접수 취소</span>}
                    {value.allocate_state === 4 && <span>통화 끊김</span>}
                </div>
                <div className={styles.modal_subdiv}>
                    <div>노선</div>
                    <div>{routeName}</div>
                </div>
                <div className={styles.modal_subdiv}>
                    <div>승차</div>
                    <div>{onName + " " + value.allocate_get_on}</div>
                </div>
                <div className={styles.modal_subdiv}>
                    <div>하차</div>
                    <div>{offName + " " + value.allocate_get_off}</div>
                </div>
            </div>
            <div className={`${styles.modal_contents}`} style={{ width: "300px" }}>
                <div className={styles.modal_subtitle}>
                    상태
                </div>
                <div className={styles.modal_chkbox}>
                    {/* <div>
                        <input
                            id="id1"
                            className={styles.circle_box}
                            type="checkbox"
                            checked={check.includes("배차완료")}
                            value="배차완료"
                            onChange={(e) => handleChangeCheck(e.target.value)}
                        />
                        <label htmlFor="id1">배차완료</label>
                    </div> */}
                    <div>
                        <input
                            id="id2"
                            className={styles.circle_box}
                            type="checkbox"
                            checked={check.includes("접수취소")}
                            value="접수취소"
                            onChange={(e) => handleChangeCheck(e.target.value)}
                        />
                        <label htmlFor="id2">접수취소</label>
                    </div>
                    {/* <div>
                        <input
                            id="id3"
                            className={styles.circle_box}
                            type="checkbox"
                            checked={check.includes("통화끊김")}
                            value="통화끊김"
                            onChange={(e) => handleChangeCheck(e.target.value)}
                        />
                        <label htmlFor="id3">통화끊김</label>
                    </div> */}
                </div>
                <textarea className={styles.modal_txtarea} placeholder='메모를 입력하세요.' onChange={(e) => handleChangeReason(e.target.value)} />
            </div>
            <div className={styles.modal_btn_area}>
                <button className={styles.submit_btn} onClick={submitBtn}>저장</button>
                <button className={styles.cancel_btn} onClick={() => cancelBtn()}>닫기</button>
            </div>
        </div>
    )

}

export default WorkModal;