import Swal from "sweetalert2";

// Swal.fire({
// 	title: "접근할 수 없는 페이지입니다.",
// 	icon: "error",
// 	confirmButtonText: "확인",
// 	confirmButtonColor: "#FF0000",
// }).then((res) => {
// 	if (res.isConfirmed) {
// 		document.location.href = '/login'
// 	}
// });






function PrivateRouteTwo({ authenticated, component: Component }) {
  // return console.log(authenticated.type);

  return authenticated.type !== "" ?

    authenticated.type === 2 ?

      Component

      : authenticated.type === 1 ?
        document.location.replace("/main") : document.location.replace("/moniter")
    : document.location.replace("/")

}



// function PrivateRoute({ authenticated, component: Component }) {
//   return JSON.stringify(authenticated) !== null
//     ? authenticated.type === "관리자" || authenticated.type === "개발자"
//       ? Component : Swal.fire({
//         title: "로그인 필요",
//         text: "로그인 하시겠습니까?",
//         icon: "error",
//         showDenyButton: true,
//         confirmButtonColor: "#3085d6",
//         confirmButtonText: "확인",
//         denyButtonText: "취소",
//       }).then((res) => {
//         if (res.isConfirmed) {
//           document.location.replace("/login");
//         }
//       })
//     : Swal.fire({
//       title: "로그인 필요",
//       text: "로그인 하시겠습니까?",
//       icon: "error",
//       showDenyButton: true,
//       confirmButtonColor: "#3085d6",
//       confirmButtonText: "확인",
//       denyButtonText: "취소",
//     }).then((res) => {
//       if (res.isConfirmed) {
//         document.location.replace("/login");
//       }
//     });
// }
// export function PrivateRouteAdmin({ authenticated, component: Component }) {
//   return authenticated
//     ? Component
//     : Swal.fire({
//         title: "로그인 필요",
//         text: "로그인 하시겠습니까?",
//         icon: "error",
//         showDenyButton: true,
//         confirmButtonColor: "#3085d6",
//         confirmButtonText: "확인",
//         denyButtonText: "취소",
//       }).then((res) => {
//         if (res.isConfirmed) {
//           document.location.replace("/login");
//         }
//       });
// }
export default PrivateRouteTwo;
