import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/appmg.module.css"
import Moment from "react-moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
import TostEditor from "../common/ToastEditor";
import ToastEditorTerm from "../common/ToastEditorTerm";
const TermInner = () => {
    const navigate = useNavigate();
    const [selectData, setSelectData] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const [content, setContent] = useState("");
    const [data, setData] = useState([]);
    const [everyCheck, setEveryCheck] = useState(0);
    const { idx } = useParams()
    const dispatch = useDispatch();
    const [check, setCheck] = useState(false);

    const [tokenSwitch, setTokenSwitch] = useState(false);
    const location = useLocation();

    const getData = () => {


        let sendData = {

            no: idx,
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/select_term",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    setContent(result.messageinfo.message[0].term_content);
                    setCheck(true);
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        getData();
        setCheck(false);

    }, [idx])


    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>약관</span>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div>
                        <ul style={{ display: "flex", padding: "10px 15px" }}>
                            <li style={{ flex: "1" }}>
                                <Link to="/appmg/termmg/1"
                                    style={{
                                        textAlign: "center", display: "block", padding: "3px 4px",
                                        borderBottom: location.pathname.indexOf("1") !== -1 ? "3px solid #004EA2" : "",
                                        fontWeight: location.pathname.indexOf("1") !== -1 ? "700" : "500",
                                        color: location.pathname.indexOf("1") !== -1 ? "#004EA2" : "#ccc",
                                    }}>
                                    <span>이용약관</span></Link>
                            </li>
                            <li style={{ flex: "1" }}>
                                <Link to="/appmg/termmg/2" style={{
                                    textAlign: "center", display: "block", padding: "3px 4px",
                                    borderBottom: location.pathname.indexOf("2") !== -1 ? "3px solid #004EA2" : "",
                                    fontWeight: location.pathname.indexOf("2") !== -1 ? "700" : "500",
                                    color: location.pathname.indexOf("2") !== -1 ? "#004EA2" : "#ccc",
                                }}><span>개인정보수집및3자제공</span></Link></li>
                            <li style={{ flex: "1" }}>
                                <Link to="/appmg/termmg/3" style={{
                                    textAlign: "center", display: "block", padding: "3px 4px",
                                    borderBottom: location.pathname.indexOf("3") !== -1 ? "3px solid #004EA2" : "",
                                    fontWeight: location.pathname.indexOf("3") !== -1 ? "700" : "500",
                                    color: location.pathname.indexOf("3") !== -1 ? "#004EA2" : "#ccc",
                                }}><span>위치기반서비스약관</span></Link></li>
                            <li style={{ flex: "1" }}>
                                <Link to="/appmg/termmg/4" style={{
                                    textAlign: "center", display: "block", padding: "3px 4px",
                                    borderBottom: location.pathname.indexOf("4") !== -1 ? "3px solid #004EA2" : "",
                                    fontWeight: location.pathname.indexOf("4") !== -1 ? "700" : "500",
                                    color: location.pathname.indexOf("4") !== -1 ? "#004EA2" : "#ccc",
                                }}><span>마케팅수신동의</span></Link></li>
                            {/* <li style={{ flex: "1" }}>
                                <Link to="/appmg/termmg/5" style={{ textAlign: "center", display: "block", padding: "0 4px" }}><span>마케팅수신동의</span></Link></li> */}
                        </ul>
                    </div>
                    {check && content && <ToastEditorTerm setContent={setContent} content={content} />}
                    {/* {routeData && <NomalTable columns={columns} data={routeData} type={"faq"} />} */}
                </div>
            </div>
        </div>
    </div >
}

export default TermInner