import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: "",
    uniq: "",
    type: "",
}
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        setUser(state, action) {
            state.id = action.payload.id;
            state.uniq = action.payload.uniq;
            state.type = action.payload.type;
        },
        reset(state, action) {
            state.id = "";
            state.uniq = "";
            state.type = "";
        }


    },
    extraReducers: builder => { } //비동기 액션
});


export default userSlice;