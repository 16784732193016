import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import "../styles/common.css"
const UseHeader = () => {


    return (
        <>
            <Header />
            <Outlet />

        </>
    )
}

export default UseHeader;