
import FirstBusBox from "../components/manage/admin/FirstBusBox"
import FirstTaxiBox from "../components/manage/admin/FirstTaxiBox"
import LastBox from "../components/manage/admin/LastBox"
import MiddleBusBox from "../components/manage/admin/MiddleBusBox"
import MiddleTaxiBox from "../components/manage/admin/MiddleTaxiBox"
import QnaInner from "../components/manage/appmg/QnaInner"
import Menu from "../components/manage/common/Menu"


const MainView = () => {



    const First = () => {
        return <div style={{ display: "flex" }}>
            <div style={{ flex: "1", marginRight: "10px" }}>

                <FirstBusBox />
            </div>
            <div style={{ flex: "1" }}>
                <FirstTaxiBox />
            </div>
        </div>
    }
    const Middle = () => {

        return <div style={{ marginTop: "10px" }}>
            <MiddleBusBox />
            <div style={{ marginTop: "10px" }}>
                <MiddleTaxiBox />
            </div>
        </div>
    }
    const Last = () => {
        return <div style={{ marginTop: "10px" }}>
            <LastBox />
        </div>
    }
    return (

        <div style={{ minHeight: "calc(100vh-60px)", padding: "40px" }}>

            <First />
            <Middle />
            <Last />
        </div>
    )
}

export default MainView