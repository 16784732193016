
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminRecordAxios, CommonAxios } from "../module/CommonAxios";
import line from "../styles/appmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import NoticeInfoWrap from "../components/manage/appmg/NoticeInfoWrap";
import { useSelector } from "react-redux";
import moment from "moment";
const AppNoticeInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();

    const user = useSelector(state => state.user);
    const removeNotice = () => {
        const result = window.confirm("해당 공지사항을 삭제하시겠습니까?");
        if (result) {
            let sendData = {
                no: idx,
            };
            let record = {
                record_category: "공지사항",
                record_user: user.uniq,
                record_text: `''${title}''라는 공지사항을 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/notice_remove",
                sendData,
                function (result) {
                    AdminRecordAxios(record);
                    window.alert("삭제되었습니다.");
                    navigate(`/appmg/noticemg`);
                }




            );

        }

    }
    const [title, setTitle] = useState("")
    return (
        <>

            <div className="common-wrap">
                <div className={line.notice_wrap}>

                    <div className={line.notice_box}>
                        <div className={line.notice_content}>
                            <div className={line.notice_content_top}>
                                <div className={line.notice_inner}>
                                    <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />

                                    {idx === "0" && <div className={`${line.notice_menu_li}`}>공지사항 등록</div>
                                    }
                                    {idx !== "0" && <div className={`${line.notice_menu_li}`}>공지사항 상세</div>
                                    }

                                </div>
                                {idx !== "0" && <button onClick={removeNotice}>삭제</button>}


                            </div>
                            <NoticeInfoWrap setTitle={setTitle} title={title} />
                            {/* <StopInfoWrap /> */}
                            {/* <MapWrap /> */}
                        </div>  </div>
                </div>
            </div>
        </>
    )
}

export default AppNoticeInfo