import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import styles from "./../styles/control.module.css";
import { Scrollbars } from 'react-custom-scrollbars';
import * as CommonAxios from "../components/CommonAxios";
import io from 'socket.io-client';
// import axios from "axios";
import { FaRegCircleUser } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import phone_icon from "./../assets/icons/phone_call.png";
// import bus_icon from "./../assets/icons/bus_call.png";
// import taxi_icon from "./../assets/icons/taxi_call.png";
import calendar from "./../assets/icons/calender.png";
import CustomSubTable from '../components/CustomSubTable';
// import RouteData from "./../pages/RouteData"
import { useNavigate } from "react-router-dom";
import bus from "./../assets/icons/bus_icon.png";
// import bus_stop from "./../assets/icons/bus_stop.png";
import stop_blue from "./../assets/icons/map_icon_color.png";
import stop_white from "./../assets/icons/map_icon.png";
// import call_icon from "./../assets/icons/call_icon.png";
import start_icon from "./../assets/icons/start_icon.png";
import end_icon from "./../assets/icons/end_icon.png";
import moment from "moment";
import Menu from '../components/leftMenu/Menu';
import CallMenu from '../components/leftMenu/CallMenu';
import RunModal from '../components/modal/RunModal';
import ControlLeft from '../components/manage/control/ControlLeft';
import Draggable from "react-draggable"
import { FaLocationDot } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import { FaBus } from "react-icons/fa"; import { PiCircleFill, PiCircle } from "react-icons/pi";
import controlSlice from '../slices/control';

const { kakao } = window;

const ControlBusPage = () => {
    const control = useSelector(state => state.control);
    const user = useSelector(state => state.user);

    const navigate = useNavigate();

    const [socket, setSocket] = useState();

    const [titleArr, setTitleArr] = useState([]);       // 가져온 노선명 배열 데이터
    const [sctTitle, setSctTitle] = useState("");       // 선택한 노선명 데이터

    const [routeArr, setRouteArr] = useState([]);       // 가져온 전체 노선 배열 데이터
    // const [sctRoute, setSctRoute] = useState([]);       // 선택한 노선 데이터

    const [stopArr, setStopArr] = useState([]);         // 노선이 존재하는 정류장 데이터
    const [allStop, setAllStop] = useState([]);         // 전체 정류장

    const [mapInfo, setMaps] = useState(null);          // 카카오 지도 데이터
    const [num, setNum] = useState(0);                  // 셀렉박스 첫 선택 이후 기존 마커 지우기 용도
    const [markerArr, setMarkerArr] = useState([]);     // 출발지, 경유지, 목적지 이름,위,경도 데이터 배열

    const [startMark, setStartMark] = useState([]);
    const [endMark, setEndMark] = useState([]);

    const [lineArr, setLineArr] = useState([]);

    const [typeVal, setTypeVal] = useState("bus");      // 왼쪽 메뉴 타입
    const [busVal, setBusVal] = useState([]);           // 버스 업무 처리 목록 배열
    const [taxiVal, setTaxiVal] = useState([]);         // 택시 업무 처리 목록 배열

    const [mapType, setMapType] = useState("bus");      // 지도 위 타입

    const [menuBool, setMenuBool] = useState(false);    // 정류장콜 메뉴 여부
    const [runModal, setRunModal] = useState(false);    // 수동 배차 모달창 여부

    const menuBoolRef = useRef(menuBool);


    const [urls, setUrls] = useState("");             // 구루미 url주소
    const [callCode, setCallCode] = useState("");       // 콜 처리코드
    const [stopName, setStopName] = useState("");       // 정류장 고유번호
    const [sentDate, setSentDate] = useState("");       // 발신일시
    const [getDate, setGetDate] = useState("");         // 수신일시
    const [rIds, setRIds] = useState("");               // 삭제용 방id

    const [urlBool, setUrlBool] = useState(false);

    const [callData, setCallData] = useState("");
    const [callList, setCallList] = useState([]);

    const [modal, setModal] = useState(false);
    const [busListData, setBusListData] = useState([]);
    const onChangeTitle = useCallback((e) => {
        let getData = e.target.value;
        setSctTitle(getData);
    }, [])
    useEffect(() => {
        // const newSocket = io.connect('http://192.168.35.131:5000');
        // const newSocket = io.connect("http://192.168.45.23:5000")
        // const newSocket = io.connect("http://yjrf.kr:80")
        // const newSocket = io.connect('https://localhost:3000/')
        const newSocket = io.connect('http://yjview.kr:80');

        // const newSocket = io.connect('http://127.0.0.1:5000');
        // const newSocket = io.connect('http://192.168.35.131:5000');
        // const newSocket = io.connect("http://183.111.126.123:80");

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
            setSocket(null);
        }
    }, [])


    const [posits, setPosits] = useState();
    const [positArr, setPositArr] = useState([]);
    const [positMark, setPositMark] = useState([]);
    const [newWin, setNewWin] = useState(null);
    const [overlayMark, setOverlayMark] = useState([]);         // 버스 오버레이

    useEffect(() => {

        if (user) {
            if (!socket) return;
            console.log(user);
            socket.on('connect', () => {
                console.log("연결완료!");

            });

            socket.on('position', ro => {
                // console.log(ro);
                setPosits(ro);
                // dispatch(controlSlice.actions.changeData(
                //     ro
                // ))
            })
            // socket.on('position', ro => {
            //     console.log(ro);


            // dispatch(controlSlice.actions.changeData(
            //     ro
            // ))

            // })
        }

    }, [socket])


    useEffect(() => {
        if (posits != null) {
            console.log(posits);
            let arr = positArr;
            if (posits.state == 1) {
                let filterArr = arr.filter((item) => item.list_no == posits.list_no);
                if (filterArr.length > 0) {
                    let mapArr = arr.map((item) => item.list_no == posits.list_no ? { ...item, longitude: posits.longitude, latitude: posits.latitude, list_no: posits.list_no, nextStop: posits.nextStop } : item);
                    setPositArr(mapArr);
                    dispatch(controlSlice.actions.changeData(
                        posits
                    ))
                } else {
                    setPositArr([...positArr, posits]);
                }
            } else {
                let filterArr = arr.filter((item) => item.list_no !== posits.list_no);
                if (filterArr.length > 0) {
                    setPositArr(filterArr);
                } else {
                    setPositArr([]);
                    dispatch(controlSlice.actions.changeData(
                        posits
                    ))
                }
            }
        }
    }, [posits])


    // useEffect(() => {
    //     if (posits != null) {
    //         console.log(posits);
    //         let arr = control.busList;
    //         if (posits.state == 1) {
    //             let filterArr = arr.filter((item) => item.list_no == posits.list_no);
    //             if (filterArr.length > 0) {
    //                 let mapArr = arr.map((item) => item.list_no == posits.list_no ? { ...item, longitude: posits.longitude, latitude: posits.latitude, list_no: posits.list_no, nextStop: posits.nextStop } : item);
    //                 // setPositArr(mapArr);
    //                 dispatch(controlSlice.actions.changeData(
    //                     posits
    //                 ))
    //             } else {
    //                 // setPositArr([...positArr, posits]);
    //             }
    //         } else {
    //             dispatch(controlSlice.actions.changeData(
    //                 posits
    //             ))
    //         }
    //     }
    // }, [posits])


    useEffect(() => {
        if (positArr.length > 0) {


            let markers = [];
            let overlays = [];
            let mapData = titleArr.filter((no) => no.no == route);
            if (mapData.length > 0) {
                let sctNum = mapData[0].no;

                let chkArr = control.busList.filter((item) => item.route_no == sctNum);

                let positions = chkArr.map((item) => {
                    let data = new kakao.maps.LatLng(item.latitude, item.longitude);
                    return (
                        {
                            latlng: data, list_no: item.list_no
                        }
                    )
                })

                const imgSrc2 = bus;
                let imageSize2 = new kakao.maps.Size(30, 30);                         // 마커 이미지 사이즈
                let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize2);    // 마커에 이미지 등록

                if (positMark.length > 0) {
                    let cnt = positMark.length;
                    for (let i = 0; i < cnt; i++) {
                        positMark[i].setMap(null);
                    }
                    let cnt2 = overlayMark.length;
                    for (let i = 0; i < cnt2; i++) {
                        overlayMark[i].setMap(null);
                    }

                    if (positions.length > 0) {
                        for (let i = 0; i < positions.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions[i].latlng,
                                image: markerImage2
                            })
                            let busNums = busListData.filter((a) => a.no == positions[i].list_no);

                            let name = "";
                            if (busNums.length > 0) {

                                name = busNums[0].list_car_number;
                            }

                            var content = '<div class="customoverlay">' +
                                `    <span class="title">${mapData[0].route_name} | ${name}</span>` +
                                '</div>';
                            var customOverlay = new kakao.maps.CustomOverlay({
                                map: mapInfo,
                                position: positions[i].latlng,
                                content: content,
                                yAnchor: 1
                            });
                            overlays.push(customOverlay);



                            markers.push(marker);
                        }
                        setPositMark(markers);
                        setOverlayMark(overlays);
                    }

                } else {
                    if (positions.length > 0) {
                        for (let i = 0; i < positions.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions[i].latlng,
                                image: markerImage2
                            })
                            let busNums = busListData.filter((a) => a.no == positions[i].list_no);
                            let name = "";
                            if (busNums.length > 0) {

                                name = busNums[0].list_car_number;
                            }

                            var content = '<div class="customoverlay">' +
                                `    <span class="title">${mapData[0].route_name} | ${name}</span>` +
                                '</div>';
                            var customOverlay = new kakao.maps.CustomOverlay({
                                map: mapInfo,
                                position: positions[i].latlng,
                                content: content,
                                yAnchor: 1
                            }); overlays.push(customOverlay);


                            markers.push(marker);
                        }
                        setPositMark(markers);
                        setOverlayMark(overlays);
                    }
                }
            } else {
                if (positMark.length > 0) {
                    let cnt = positMark.length;
                    for (let i = 0; i < cnt; i++) {
                        positMark[i].setMap(null);
                    }
                    let cnt2 = overlayMark.length;
                    for (let i = 0; i < cnt2; i++) {
                        overlayMark[i].setMap(null);
                    }

                }
            }

        } else {
            if (positMark.length > 0) {
                let cnt = positMark.length;
                for (let i = 0; i < cnt; i++) {
                    positMark[i].setMap(null);
                }
                let cnt2 = overlayMark.length;
                for (let i = 0; i < cnt2; i++) {
                    overlayMark[i].setMap(null);
                }

            }
        }
    }, [positArr])



    const [route, setRoute] = useState(0);
    const [routeData, setRouteData] = useState([]);
    const [info, setInfo] = useState([]);


    useEffect(() => {


        let sendData = {
            data: ""
        };

        // 버스 노선 정보(노선이름, 노선정보, 공개여부)
        CommonAxios.CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/control_select_list",
            sendData,
            function (result) {
                if (result.messageinfo.state == "ok") {
                    console.log(result.messageinfo.message_data3);
                    setBusListData(result.messageinfo.message_data3)

                    setTitleArr(result.messageinfo.message)
                    setRouteData(result.messageinfo.message)
                    setInfo(result.messageinfo.message_data2)
                    setRoute(0)
                } else {

                }
            }
        );

    }, [])
    const [lineData, setLineData] = useState([]);
    const [stopData, setStopData] = useState([]);

    useEffect(() => {

        // console.log(route);
        let sendData = {
            where: "",
        };
        if (route !== 0) {
            sendData.where = route
        }
        CommonAxios.CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/control_map",
            sendData,
            function (result) {
                if (result.messageinfo.state == "ok") {
                    setLineData(result.messageinfo.message)






                    setStopData(result.messageinfo.message_data2);
                    // setRoute(result.messageinfo.message)
                } else {

                }
            }
        );


    }, [route])

    useEffect(() => {


        const container = document.getElementById('map');
        const options = {
            // center: new kakao.maps.LatLng(36.806744385094596, 127.80419313104726),
            // center: new kakao.maps.LatLng(36.63846052508691, 127.44473659298507),
            center: new kakao.maps.LatLng(36.809165585385955, 127.79723697515438),
            level: 6
        }

        const map = new kakao.maps.Map(container, options);
        setMaps(map);
    }, [])
    useEffect(() => {

        const imgSrc1 = stop_blue;
        const imgSrc2 = stop_white;

        let imageSize = new kakao.maps.Size(30, 30);                         // 마커 이미지 사이즈
        let markerImage1 = new kakao.maps.MarkerImage(imgSrc1, imageSize);    // 마커에 이미지 등록
        let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize);    // 마커에 이미지 등록

        // const imgSrc2 = bus;
        // let imageSize2 = new kakao.maps.Size(15,15);                         // 마커 이미지 사이즈
        // let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize2);    // 마커에 이미지 등록
        if (lineData.length > 0) {
            if (lineArr.length > 0) {
                lineArr[0].setMap(null);
            }
            let line = [];
            lineData.map((data2) => {
                if (data2.line_latitude && data2.line_longitude) {
                    line.push(new kakao.maps.LatLng(data2.line_latitude, data2.line_longitude))
                }





            })


            var polyline = new kakao.maps.Polyline({
                path: line, // 선을 구성하는 좌표배열 입니다
                strokeWeight: 5, // 선의 두께 입니다
                strokeColor: '#FFAE00', // 선의 색깔입니다
                strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                strokeStyle: 'solid' // 선의 스타일입니다
            });
            polyline.setMap(mapInfo);
            setLineArr([polyline])
        }
        let markers = [];
        if (stopData.length > 0) {
            for (var i = 0; i < markerArr.length; i++) {
                markerArr[i].setMap(null);
            }
            for (var j = 0; j < stopData.length; j++) {
                if (stopData[j].rs_stop_essential === 1) {
                    var marker = new kakao.maps.Marker({
                        map: mapInfo,
                        position: new kakao.maps.LatLng(stopData[j].stop_latitude, stopData[j].stop_longitude),
                        title: stopData[j].stop_name,
                        image: markerImage1,
                    })
                    // var infowindow = new kakao.maps.InfoWindow({
                    //     content: stopData[i].stop_name // 인포윈도우에 표시할 내용
                    // });
                    markers.push(marker);
                } else {
                    var marker = new kakao.maps.Marker({
                        map: mapInfo,
                        position: new kakao.maps.LatLng(stopData[j].stop_latitude, stopData[j].stop_longitude),
                        title: stopData[j].stop_name,
                        image: markerImage2,
                    })
                    // var infowindow = new kakao.maps.InfoWindow({
                    //     content: stopData[j].stop_name // 인포윈도우에 표시할 내용
                    // });
                    markers.push(marker);
                }
            }
            setMarkerArr(markers);

        }
    }, [lineData])
    const [position, setPosition] = useState({ x: 0, y: 0 }); // box의 포지션 값
    // 업데이트 되는 값을 set 해줌
    const trackPos = (data) => {
        setPosition({ x: data.x, y: data.y });
    };
    const dispatch = useDispatch();
    const [busPop, setBusPop] = useState(false);
    const [carNo, setCarNo] = useState("");
    const [routeNo, setRouteNo] = useState("");
    const [busArr, setBusArr] = useState([]);
    const [stopPop, setStopPop] = useState(false);
    const [stopArr2, setStopArr2] = useState([]);
    const [routePop, setRoutePop] = useState(false);
    const [routeArr2, setRouteArr2] = useState([]);


    useEffect(() => {

        if (control.carNo) {
            if (control.routeNo) {
                let dataArr = [];
                info.map((item) => {
                    if (item.rs_route_no === control.routeNo) {
                        dataArr.push(item);
                    }

                })
                setBusArr(dataArr);
            }
        }
    }, [control.carNo, control.routeNo])
    useEffect(() => {

        if (control.stopNo) {
            let dataArr = [];
            info.map((item) => {
                if (item.stop_number === control.stopNo) {
                    dataArr.push(item);
                }

            })

            setStopArr2(dataArr);

        }
    }, [control.stopNo])
    useEffect(() => {

        if (control.routeNo2) {

            let dataArr = [];
            info.map((item) => {
                if (item.rs_route_no === control.routeNo2) {
                    dataArr.push(item);
                }

            })
            setRouteArr2(dataArr);

        }
    }, [control.routeName, control.routeNo2])
    const ItemList3 = ({ item }) => {
        return <div className={styles.bus_pop_box}>
            <div>
                {item.rs_stop_essential === 1 ?
                    <PiCircleFill /> : <PiCircle />}
            </div>

            <div>


                <p >{item.stop_name} {item.stop_number}</p>


            </div>

            <div>


                <p >{item.stop_direction} </p>


            </div>

        </div>
    }
    const ItemList4 = ({ item }) => {
        const dataClick = (data) => {
            dispatch(
                controlSlice.actions.setRoutePop2({
                    routeName: data.route_name,
                    routeNo2: data.rs_route_no,

                })
            )
            setRoutePop(true);
        }
        return <div className={styles.bus_pop_box} onClick={() => dataClick(item)}>
            <p>      {item.route_name}
            </p>

            {/* rs_route_no */}
        </div>
    }


    const ItemList5 = ({ item }) => {
        return <div className={styles.bus_pop_box}>
            <div>
                {item.rs_stop_essential === 1 ?
                    <PiCircleFill /> : <PiCircle />}
            </div>

            <div>


                <p >{item.stop_name} {item.stop_number}</p>


            </div>

            <div>


                <p >{item.stop_direction} </p>


            </div>

        </div>
    }

    const raceState = () => {
        // console.log(posits)

        if (control.busList.length > 0) {
            let count = 0;
            control.busList && control.busList.map((data) => {
                if (data.list_no === control.carUniq) {
                    if (data.state === 1) {
                        count++;
                    }
                }
            })
            if (count > 0) {
                return "운행중"
            } else {
                return "운행대기중"
            }
        }

    }
    const raceState2 = () => {
        // console.log(posits)
        // if (positArr !== null) {
        if (control.busList.length > 0) {
            let aa = "";
            control.busList && control.busList.map((data) => {
                if (data.list_no === control.carUniq) {

                    aa = data.nextStop;
                }
            })
            return aa;
        }
        // }
    }
    const raceState3 = () => {
        // console.log(posits)
        // if (positArr !== null) {
        if (control.busList.length > 0) {
            let aa = "";
            control.busList && control.busList.map((data) => {
                if (data.list_no === control.carUniq) {

                    aa = data.start_date;
                }
            })
            return aa;
        }
        // }
    }
    return (
        <>
            {busPop && <Draggable onDrag={(e, data) => trackPos(data)} >
                <div className={styles.control_popupBack}>
                    <div className={styles.popup_top}>
                        <div className={styles.popup_top_title}>
                            <div>
                                <span>버스 정보</span>
                                <FaLocationDot />
                            </div>
                            <button onClick={() => setBusPop(false)}><IoIosClose /></button>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <label>상태</label>
                                    <span>{raceState()}</span>
                                </li>
                                <li>
                                    <label>다음정류장</label>
                                    <span>{raceState2()}</span>
                                </li>
                                <li>
                                    <label>버스번호</label>
                                    <span>{control.carNo}</span>
                                </li>
                                <li>
                                    <label>운행시작시간</label>
                                    <span>{raceState3()}</span>
                                </li>
                            </ul>
                        </div>


                    </div>
                    <div className={styles.popup_bottom}>
                        <div className={styles.popup_bottom_title}>
                            <span>
                                <FaBus />
                            </span>
                            <span>{busArr.length > 0 && busArr[0].route_name}
                            </span>
                            <span>{busArr.length > 0 && busArr[0].bus_number}
                            </span>
                        </div>
                        <div className={styles.popup_bottom_inner}>
                            {busArr.length > 0 && busArr.map((item) => {
                                return <ItemList3 item={item} />
                            })}
                        </div>
                    </div>

                </div>
            </Draggable>
            }

            {stopPop && <Draggable onDrag={(e, data) => trackPos(data)} >
                <div className={`${styles.control_popupBack} ${styles.control_popupBack2}`}>
                    <div className={styles.popup_top}>
                        <div className={styles.popup_top_title}>
                            <div>
                                <span>정류장 정보</span>
                                <FaLocationDot />
                            </div>
                            <button onClick={() => setStopPop(false)}><IoIosClose /></button>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <label>이름</label>
                                    <span>{stopArr2.length > 0 && stopArr2[0].stop_name}</span>
                                </li>
                                <li>
                                    <label>방면정보</label>
                                    <span>{stopArr2.length > 0 && stopArr2[0].stop_direction}</span>
                                </li>
                                <li>
                                    <label>화상연결</label>
                                    <span style={{ padding: "3px 6px", background: "#004EA2", color: "#fff", borderRadius: "4px" }}>{stopArr2.length > 0 && stopArr2[0].stop_connection ? "설치" : "미설치"}</span>

                                </li>
                                {/* <li><span>운행시작시간</span></li> */}
                            </ul>
                        </div>


                    </div>
                    <div className={styles.popup_bottom}>

                        <div className={styles.popup_bottom_inner}>
                            {stopArr2.length > 0 && stopArr2.map((item) => {
                                return <ItemList4 item={item} />
                            })}
                        </div>
                    </div>

                </div>
            </Draggable>
            }

            {routePop && <Draggable onDrag={(e, data) => trackPos(data)} >
                <div className={`${styles.control_popupBack} ${styles.control_popupBack3}`}>
                    <div className={styles.popup_top}>
                        <div className={styles.popup_top_title}>
                            <div>
                                <span>노선 정보</span>
                                {/* <FaLocationDot /> */}
                            </div>
                            <button onClick={() => setRoutePop(false)}><IoIosClose /></button>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <label>노선명</label>
                                    <span>{routeArr2.length > 0 && routeArr2[0].route_name}</span>
                                </li>
                                <li>
                                    <label>운행중버스</label>
                                    <span></span>
                                </li>
                                <li>
                                    <label>전체정류장수</label>
                                    <span>{routeArr2.length > 0 && routeArr2.length}</span>
                                </li>
                                {/* <li><span>운행시작시간</span></li> */}
                            </ul>
                        </div>


                    </div>
                    <div className={styles.popup_bottom}>

                        <div className={styles.popup_bottom_inner}>
                            {routeArr2.length > 0 && routeArr2.map((item) => {
                                return <ItemList5 item={item} />
                            })}
                        </div>
                    </div>

                </div>
            </Draggable>
            }


            <div className={styles.main_container}>
                {/* <div className={styles.control_popupBack} > */}


                {/* </div> */}


                <ControlLeft setBusPop={setBusPop}
                    setStopPop={setStopPop}
                    positArr={positArr} routeData={routeData} setRoute={setRoute} route={route} />
                {/* <Scrollbars className={styles.main_left_div} style={{ width: 361, height: 828 }}>


            </Scrollbars> */}

                <div className={styles.main_right_div}>
                    <div id="map" style={{ width: "100%", height: `calc(100% - 53px)` }}></div>
                    {/* <div className={styles.map_type_box}>
                    <div className='bus' style={mapType == "bus" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setMapType("bus")}>버스</div>
                    <div className='taxi' style={mapType == "taxi" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setMapType("taxi")}>택시</div>
                </div> */}
                    {/* {mapType == "bus" &&
                    <select className={styles.map_select_box} value={sctTitle} onChange={onChangeTitle}>
                        <option value={""}>노선 선택</option>
                        {titleArr.map((a) => {
                            return (
                                <option key={a.route_name} value={a.route_name}>{a.route_name}</option>
                            )
                        })}
                    </select>
                } */}

                </div>

            </div></>
    )
}

export default ControlBusPage;