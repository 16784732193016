
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminRecordAxios, CommonAxios } from "../module/CommonAxios";
import line from "../styles/appmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import moment from "moment";
import FaqInfoWrap from "../components/manage/appmg/FaqInfoWrap";
const AppFaqInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();
    const user = useSelector(state => state.user);
    const removeQna = () => {
        const result = window.confirm("해당 FAQ를 삭제하시겠습니까?");
        if (result) {

            let sendData = {
                no: idx,
            };
            let record = {
                record_category: "FAQ",
                record_user: user.uniq,
                record_text: `''${title}''라는 FAQ를 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/faq_delete",
                sendData,
                function (result) {
                    AdminRecordAxios(record);
                    window.alert("삭제되었습니다.");
                    navigate(`/appmg/faqmg`);
                }




            );
        }

    }
    const [title, setTitle] = useState("")
    return (
        <>

            <div className="common-wrap">
                <div className={line.qna_wrap}>

                    <div className={line.qna_box}>
                        <div className={line.qna_content}>
                            <div className={line.qna_content_top}>
                                <div className={line.qna_inner}>
                                    <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />

                                    {idx === "0" &&


                                        <div className={`${line.qna_menu_li}`}>FAQ 등록</div>

                                    }{idx !== "0" &&


                                        <div className={`${line.qna_menu_li}`}>FAQ 상세</div>

                                    }
                                </div>
                                {idx !== '0' && <button onClick={removeQna}>삭제</button>}


                            </div>

                            <FaqInfoWrap setTitle={setTitle} title={title} />

                        </div>  </div>
                </div>
            </div>
        </>
    )
}

export default AppFaqInfo