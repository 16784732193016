import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/busmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
import RaceInfoWrap from "./RaceInfoWrap";
const RaceInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const { idx } = useParams()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        },
        {
            Header: "버스번호",
            accessor: "bus_number",
            Cell: (row) => {
                let data = row.cell;
                let oriNo = row.cell.row.original.no;
                let thumbnail = row.cell.row.original.event_thumbnail;

                return (
                    <>

                        {data.value}

                    </>
                );
            },
        },
        {
            Header: "운수사",
            accessor: "com_name",
        },

        {
            Header: "차량번호",
            accessor: "list_car_number",
        },
        {
            Header: "기사명",
            accessor: "user_name",
        },

        {
            Header: "운행시작",
            accessor: "record_start_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "연락처",
            accessor: "user_mobile",
            Cell: ({ cell: { value } }) => <Phone value={value} />,
        },
        {
            Header: "시작정류장",
            accessor: "record_start_stop",
        },
        {
            Header: "종료정류장",
            accessor: "record_end_stop",
        },
        {
            Header: "운행종료",
            accessor: "record_end_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "운행궤적",
            accessor: "record_id",
            Cell: ({ cell: { value } }) => <ButtonData value={value} />,
        },
    ]);

    const [viewData, setViewData] = useState("");
    const ButtonData = ({ value }) => {
        const viewModal = () => {
            setClose(true);
            setViewData(value);
        }
        return <button onClick={viewModal}>
            보기
        </button>
    }
    const Phone = ({ value }) => {
        return <>{value ? value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : ""}</>
    };
    //accessor 스타일링
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const DetailInfo = (oriNo) => {
        navigate(`/admin/event/${oriNo}`);
    };


    const data = [
        {}
    ]
    const cookies = new Cookies()


    const [tokenSwitch, setTokenSwitch] = useState(false);
    const getData = () => {

        let sendData = {
            startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: searchValue,
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/race_table",
            sendData,
            function (result) {
                if (result.messageinfo) {

                    if (result.messageinfo.message.length > 0) {
                        setRouteData(result.messageinfo.message);
                        // setTokenSwitch(true);
                    } else {
                        // setTokenSwitch(true);
                    }
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };

                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {


        getData();



    }, [tokenSwitch])


    // useEffect(() => {


    //     if (viewData) {
    //         let sendData = {
    //             record_id: viewData
    //         };

    //         CommonAxiosToken(
    //             process.env.REACT_APP_HOSTDONAME + "/api/admin/race_detail",
    //             sendData,
    //             function (result) {
    //                 if (result.result.length > 0) {

    //                 }
    //             })
    //     }

    // }, [viewData])
    const searchFun = () => {
        getData();
    }
    const resetFun = () => {
        setStartDate("")
        setEndDate("");
        setSearchValue("");
        getData();
    }
    const onValue = useCallback((e) => {
        setSearchValue(e.target.value)
    }, [])

    const [close, setClose] = useState(false);
    return <div className={manage.manage_wrap}>

        <div className={manage.manage_box} style={{ position: "relative" }}>
            {close && <RaceInfoWrap setClose={setClose} setViewData={setViewData} viewData={viewData} close={close} />}
            {!close &&
                <div>
                    <div className={manage.manage_top}>
                        <span>주행 기록</span>
                    </div>
                    <div className={manage.manage_middle} style={{ position: "relative" }}>

                        <div>
                            <div className={manage.mange_search_wrap}>
                                <div className={manage.manage_search_box}>

                                    <div className={manage.manage_search_inner}>
                                        <div className={manage.manage_date}>
                                            <label>기간</label>
                                            <span className={manage.manage_datepicker}>
                                                <span>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        locale={ko}
                                                        onChange={(date) => setStartDate(date)}
                                                        maxDate={endDate}
                                                        selected={startDate}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        placeholderText={"YYYY.MM.DD"}
                                                    />
                                                </span>
                                                <span className={manage.datepicker_ico}>
                                                    <FaCalendar />
                                                </span>
                                            </span>
                                            <span>~</span>
                                            <span className={manage.manage_datepicker}>
                                                <span>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        locale={ko}
                                                        onChange={(date) => setEndDate(date)}
                                                        selected={endDate}
                                                        maxDate={window.Date}
                                                        startDate={endDate}
                                                        endDate={endDate}
                                                        placeholderText={"YYYY.MM.DD"}
                                                    />
                                                </span>
                                                <span className={manage.datepicker_ico}>
                                                    <FaCalendar />

                                                </span>
                                            </span>

                                        </div>
                                        <div className={manage.manage_search}>
                                            <label>검색어</label>
                                            <input type="text" placeholder="운수사,번호번호,차량번호,기사명" value={searchValue} onChange={onValue} />
                                        </div>
                                    </div>
                                    <div className={manage.manage_serach_button}>
                                        <button onClick={resetFun}>초기화</button>
                                        <button onClick={searchFun}>검색</button>
                                    </div>
                                </div>

                            </div>
                            {routeData && <SearchTable columns={columns} data={routeData} type={"record"} />}
                        </div>
                    </div>
                </div>}
        </div ></div>
}

export default RaceInner