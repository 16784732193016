
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAxios } from "../module/CommonAxios";
import line from "../styles/appmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";

import UserInfoWrap from "../components/manage/appmg/UserInfoWrap";
const AppUserInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();
    const removeQna = () => {
        const result = window.confirm("해당 문의를 삭제하시겠습니까?");
        if (result) {

            let sendData = {
                no: idx,
            };
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_answer_remove",
                sendData,
                function (result) {

                    window.alert("삭제되었습니다.");
                    navigate(`/appmg/qnamg`);
                }




            );
        }

    }
    return (
        <>

            {/* <div className="common-wrap"> */}
            <div className={line.qna_wrap}>

                <div className={line.qna_box}>
                    <div className={line.qna_content}>
                        <div className={line.qna_content_top}>
                            <div className={line.qna_inner}>
                                <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />


                                <div className={`${line.qna_menu_li}`}>회원 정보 상세</div>


                            </div>
                            <button onClick={removeQna}>삭제</button>


                        </div>

                        <UserInfoWrap />
                    </div>  </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default AppUserInfo