import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/appmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
const FaqInner = () => {
    const navigate = useNavigate();
    const [selectData, setSelectData] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const [routeData, setRouteData] = useState([]);
    const [data, setData] = useState([]);
    const [everyCheck, setEveryCheck] = useState(0);
    const { idx } = useParams()
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        },
        {
            Header: "분류",
            accessor: "faq_name",
            Cell: ({ cell: { value } }) => <>{value}</>
        }, {
            Header: "제목",
            accessor: "faq_title",

        },
        {
            Header: "자주하는질문",
            accessor: "faq_every",
            Cell: ({ cell: { value } }) => <>{value === 1 ? "O" : "X"}</>
        },


    ]);




    const [tokenSwitch, setTokenSwitch] = useState(false);


    const getData = () => {


        let sendData = {

            where: "",
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/faq_table",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    setSelectValue(result.messageinfo.message[0].no)
                    setSelectData(result.messageinfo.message);

                    setRouteData(result.messageinfo.message_data2.filter((data) => data.faq_category_no === result.messageinfo.message[0].no))

                    setData(result.messageinfo.message_data2);
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        getData();


    }, [])



    useEffect(() => {
        console.log(selectValue)

        if (everyCheck === 1) {
            setRouteData(data.filter((data) => data.faq_category_no === selectValue && data.faq_every === 1))
        } if (everyCheck === 0) {
            console.log(selectValue)
            setRouteData(data.filter((data) => data.faq_category_no === Number(selectValue)))
        }
    }, [everyCheck, selectValue])

    const changeEvery = (e) => {
        setEveryCheck(e);

    }

    const chageSelect = (e) => {
        setSelectValue(e.target.value)
    }
    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>FAQ</span>
                <button onClick={() => { navigate('/appmg/faqmg/faqInfo/0') }}>등록하기</button>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div className={manage.mange_search_wrap} style={{ borderBottom: 0 }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <select style={{
                                border: "1px solid #a7a7a7", width: "200px", borderRadius: "5px"
                            }}

                                onChange={chageSelect}
                            >
                                {selectData && selectData.map((data) => {
                                    return <option value={data.no} key={data.no}>{data.faq_name}</option>
                                })}
                            </select>

                            <div><span style={{ marginRight: "5px" }}>구분</span>

                                <lable style={{ cursor: "pointer" }}><input type="radio" onChange={() => changeEvery(0)} name="cate" checked={everyCheck === 0 ? true : false} /> 전체 </lable>
                                <lable style={{ cursor: "pointer" }}><input type="radio" onChange={() => changeEvery(1)} name="cate" checked={everyCheck === 1 ? true : false} /> 자주하는 질문 </lable></div>
                        </div>

                    </div>
                    {routeData && <NomalTable columns={columns} data={routeData} type={"faq"} />}
                </div>
            </div>
        </div>
    </div >
}

export default FaqInner