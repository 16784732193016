import { Link, useLocation } from "react-router-dom";
import "./header.css"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminRecordAxios, CommonAxios } from "../../module/CommonAxios";
import moment from "moment";
import userSlice from "../../slices/user";

const HeaderControl = () => {

    const user = useSelector(state => state.user);
    const location = useLocation();
    const dispatch = useDispatch();


    const logout = () => {
        let sendData = {
        }
        let record = {
            record_category: "로그아웃",
            record_user: user.uniq,
            record_text: `로그아웃하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/logout",
            sendData,
            function (result) {
                console.log(result);
                if (result) {
                    AdminRecordAxios(record);
                    dispatch(
                        userSlice.actions.reset({
                        })
                    )
                    // inputView(false);

                    // recentData();
                    // window.alert("저장 완료");

                }
            })
    }
    return (<>

        <div className="hedaer_wrap">
            <div className="header_box">
                <div className="header_logo">
                    <Link to="admin" style={{ color: "#fff" }}>
                        괴산콜버스 통합관제
                    </Link>
                </div>
                <nav className="header_nav">

                    <ul>

                        <li>
                            <Link to="/control/bus" className={location.pathname.indexOf("bus") === -1 ? "header_a" : "header_a view"} >버스관제</Link>
                        </li>
                        {/* <li>
                            <Link to="/control/route" className={location.pathname.indexOf("route") === -1 ? "header_a" : "header_a view"} >노선운행현황</Link></li> */}
                        <li>
                            <Link to="/control/race" className={location.pathname.indexOf("race") === -1 ? "header_a" : "header_a view"} >버스운행현황</Link></li>

                        {/* <li>
                            <Link to="/control/call" className={location.pathname.indexOf("call") === -1 ? "header_a" : "header_a view"} >콜현황</Link>
                        </li> */}

                    </ul>

                    <div className="header_side">
                        <span>{user.id}님</span>
                        <button onClick={logout}>로그아웃</button>
                    </div>
                </nav>
            </div>
        </div></>)
}

export default HeaderControl;