import RaceInner from "../components/manage/busmg/RaceInner"
import Menu from "../components/manage/common/Menu"


const BusRaceMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'bus'} />
            <RaceInner />
        </div>
    )
}

export default BusRaceMg