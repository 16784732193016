import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { Paging } from "./Pagination";


const CustomSubTable = ({ columns, data, sizeNum, onClickEvt }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        rows,
        setPageSize,
        state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: sizeNum } },
        useSortBy,
        usePagination,
        useRowSelect,
    );

    const [count, setCount] = useState(0);
    const [current, setCurrent] = useState(1);

    useEffect(() => {
        if (data != null) {
            setCount(data.length);
        }
    }, [data])

    useEffect(() => {
        setPageSize(sizeNum);
        setCurrent(1);
        gotoPage(0);
        setCurrent(1);
    }, [sizeNum]);

    const setPage = (e) => {
        gotoPage(e - 1);
        setCurrent(e);
    };

    useEffect(() => { }, [pageIndex]);

    const sendData = (data) => {
        onClickEvt(data);
    }



    return (
        <>
            <table className="event_log" style={{ width: "100%" }} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} style={{ cursor: "pointer" }}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()} onDoubleClick={() => sendData(row.original)}>{cell.render("Cell")}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {data && data.length > sizeNum &&
                <div className="my_write_qna admin_table paging_area">
                    <Paging
                        page={current}
                        perPage={sizeNum}
                        count={count}
                        setPage={setPage}
                    />
                </div>
            }
        </>
    );
}

export default CustomSubTable;