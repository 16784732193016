import Menu from "../components/manage/common/Menu"
import CallInner from "../components/manage/operatemg/CallInner"
import NumberInner from "../components/manage/operatemg/NumberInner"


const OperateNumberMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'operate'} />
            {/* <StopInner /> */}

            <NumberInner />
        </div>
    )
}

export default OperateNumberMg