import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/appmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
import TostEditor from "../common/ToastEditor";
const NoticeInfoWrap = ({ setTitle, title }) => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [stopDate, setStopDate] = useState("");
    const [stopKind, setStopKind] = useState("");
    const [stopName, setStopName] = useState("");
    const [stopNumber, setStopNumber] = useState("");
    const [stopDirect, setStopDirect] = useState("");
    const [stopCon, setStopCon] = useState(1);
    const [stopLat, setStopLat] = useState("");
    const [stopLong, setStopLong] = useState("");
    const columns = useMemo(() => [
        // {
        //     Header: "No",
        //     accessor: "ROWNUM",
        //     Cell: (row) => {
        //         let count = row.data.length;
        //         let { index } = row.cell.row;

        //         return (
        //             <>
        //                 <div>{count - index}</div>
        //             </>
        //         );
        //     },
        // },
        {
            Header: "버스번호",
            accessor: "bus_number",
            // Cell: (row) => {
            //     let data = row.cell;
            //     let oriNo = row.cell.row.original.no;
            //     let thumbnail = row.cell.row.original.event_thumbnail;

            //     return (
            //         <>
            //             <div
            //                 className="click_detail"
            //                 style={{ textAlign: "left", paddingLeft: "20px" }}

            //             >
            //                 <img
            //                     src={thumbnail}#FF0000
            //                     style={{ width: "60px", marginRight: "10px" }}
            //                 />
            //                 {data.value}
            //             </div>
            //         </>
            //     );
            // },
        },
        {
            Header: "등록된 차량",
            accessor: "busCnt",
            Cell: (row) => {
                let data = row.cell;


                return (
                    <>

                        <p>
                            {data.value}
                            대 </p>
                    </>
                );
            },
        },


    ]);

    const [routeInfo, setRouteInfo] = useState([]);
    const [routeBus, setRouteBus] = useState([]);
    const [routeName, setRouteName] = useState("");
    const [routeMemo, setRouteMemo] = useState("");
    const [toastSwitch, setToastSwitch] = useState(false);
    // const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [noticeDate, setNoticeDate] = useState("");
    useEffect(() => {

        if (idx !== "0") {
            let sendData = {
                no: idx
            };

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/notice_detail_info",
                sendData,
                function (result) {
                    if (result.messageinfo) {

                        setNoticeDate(moment(result.messageinfo.message[0].notice_date).format("YYYY-MM-DD HH:mm:ss"));
                        setTitle(result.messageinfo.message[0].notice_title);
                        setContent(result.messageinfo.message[0].notice_content);
                        setToastSwitch(true);

                    }
                })
        }
    }, [])



    const changeName = useCallback((e) => {
        setTitle(e.target.value)
    }, [])

    const btn_save = () => {

        let sendData = {
            notice_title: title,
            notice_content: content,
            notice_type: "1",
            no: idx === "0" ? 0 : idx
        }


        if (idx === "0") {
            let record = {
                record_category: "공지사항",
                record_user: user.uniq,
                record_text: `''${title}''라는 공지사항을 등록하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/notice_insert",
                sendData,
                function (result) {

                    if (result) {
                        AdminRecordAxios(record);
                        window.alert("저장 완료")
                        navigate(`/appmg/noticemg`);
                    }

                })
        } else {
            let record = {
                record_category: "공지사항",
                record_user: user.uniq,
                record_text: `''${title}''라는 공지사항을 수정하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/notice_update",
                sendData,
                function (result) {

                    if (result) {
                        AdminRecordAxios(record);
                        window.alert("수정 완료")
                        navigate(`/appmg/noticemg`);
                    }
                })
        }



    }
    const btn_name_save2 = () => {

        let sendData = {
            no: idx,
            route_info: routeMemo,

            type: 2,
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_route_info",
            sendData,
            function (result) {

                if (result.result) {
                    window.alert("수정 완료")

                }

            })


    }

    const btn_insert = () => {

        let sendData = {
            value: routeName
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route",
            sendData,
            function (result) {
                console.log(result);
                if (result.result) {

                    navigate(`/busmg/routemg/routeInfo/${result.result}`)
                }

            })


    }
    const data = [{}]
    return (
        <>

            <div className={line.notice_info_wrap}>
                <div className={line.notice_info_box}>
                    <div className={line.notice_info_table}>
                        {idx !== '0' &&
                            <>
                                <div className={line.notice_info_row}>
                                    <span className={line.notice_info_span}>등록일</span>
                                    <div className={line.notice_info_input}>
                                        <p>{noticeDate}</p>
                                    </div>
                                </div>

                            </>
                        }
                        <div className={line.notice_info_row}>
                            <span className={line.notice_info_span}>제목</span>
                            <div className={line.notice_info_input}>
                                <input type="text" value={title} onChange={changeName} />
                            </div>
                        </div>
                        <div className={line.notice_info_row}>
                            <span className={line.notice_info_span}>내용</span>
                            <div className={line.notice_info_input}>
                                {idx === '0' && <TostEditor setContent={setContent} content={content} />}
                                {idx !== '0' && toastSwitch && <TostEditor setContent={setContent} content={content} />}
                            </div>
                        </div>

                    </div>

                    <div className={line.notice_save}>
                        <button onClick={btn_save}>저장</button>
                    </div>

                </div>

            </div></>)
}

export default NoticeInfoWrap