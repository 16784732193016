
import QnaInner from "../components/manage/appmg/QnaInner"
import Menu from "../components/manage/common/Menu"
import MoniterIn from "../components/moniter/MoniterIn"


const MoniterPage = () => {


    return (

        <div style={{ height: "auto" }}>

            <MoniterIn />
            {/* <Menu type={'user'} />
            <QnaInner /> */}
            {/* <StopInner /> */}
        </div>
    )
}

export default MoniterPage