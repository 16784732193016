import CompanyInner from "../components/manage/busmg/CompanyInner"
import Menu from "../components/manage/common/Menu"


const BusCompanyMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'bus'} />

            <CompanyInner />
        </div>
    )
}

export default BusCompanyMg