import Menu from "../components/manage/common/Menu"
import AdminStatInner from "../components/manage/statistics/AdminStatInner"


const StatisticsAdminMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'statistics'} />
            <AdminStatInner />
        </div>
    )
}

export default StatisticsAdminMg