
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CommonAxios } from "../module/CommonAxios";
import MapWrap from "../components/manage/busmg/RouteMapWrap";
import line from "../styles/busmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import RouteInfoWrap from "../components/manage/busmg/RouteInfoWrap";
const BusRouteInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();


    // useEffect(() => {



    //     // api 연동
    //     let sendData = {};
    //     CommonAxios(
    //         process.env.REACT_APP_HOSTDONAME + "/api/source",
    //         sendData,
    //         function (result) {
    //             if (result.messageinfo.state == "ok") {
    //             } else {

    //             }
    //         }
    //     );
    // }, [])

    const remove_route = () => {



        let sendData = {
            no: idx,
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/delete_route",
            sendData,
            function (result) {
                if (result.messageinfo.state == "ok") {
                    window.alert("해당 노선을 삭제하였습니다.");
                    navigate("/busmg/routemg")
                } else {

                }
            }
        );

    }
    return (
        <>

            <div className="common-wrap">
                <div className={line.line_wrap}>

                    <div className={line.line_box}>
                        <div className={line.line_content}>
                            <div className={line.line_content_top}>
                                <div className={line.line_inner}>
                                    <FaArrowLeftLong />
                                    <ul className={line.line_menu}>
                                        <li className={`${line.line_menu_li} ${line.line_menu_li_color}`}><Link to={`/busmg/routemg/routeInfo/${idx}`}>노선정보</Link></li>
                                        <li className={line.line_menu_li}><Link to={`/busmg/routemg/busline/${idx}`}>노선경로</Link></li>
                                    </ul>
                                </div>

                                {idx !== "0" &&
                                    <div>
                                        <button onClick={remove_route}>삭제</button>
                                        {/* <button>저장</button> */}
                                    </div>
                                }
                                {/* {idx == "0" &&
                                    <div>

                                        <button style={{ background: "#004EA2" }} onClick={btn_insert}>저장</button>
                                    </div>
                                } */}
                            </div>
                            <RouteInfoWrap />
                            {/* <MapWrap /> */}
                        </div>  </div>
                </div>
            </div>
        </>
    )
}

export default BusRouteInfo