import { Outlet } from "react-router-dom";
import Header from "../components/header/Header";
import "../styles/common.css"
import HeaderControl from "../components/header/HeaderControl";
import Footer from "../components/footer/Footer";
const UseHeaderControl = () => {


    return (
        <>
            <HeaderControl />
            <Outlet />
            <Footer />
        </>
    )
}

export default UseHeaderControl;