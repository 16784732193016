
import { useNavigate, useParams } from "react-router-dom";
import { AdminRecordAxios, CommonAxios } from "../module/CommonAxios";
import line from "../styles/appmg.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import QnaInfoWrap from "../components/manage/appmg/QnaInfoWrap";
import moment from "moment";
import { useSelector } from "react-redux";
import { useState } from "react";
const AppQnaInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();
    const user = useSelector(state => state.user);

    const removeQna = () => {
        const result = window.confirm("해당 문의를 삭제하시겠습니까?");
        if (result) {

            let sendData = {
                no: idx,
            };
            let record = {
                record_category: "qna",
                record_user: user.uniq,
                record_text: `''${mobile}''님의 질문을 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/qna_answer_remove",
                sendData,
                function (result) {
                    AdminRecordAxios(record)
                    window.alert("삭제되었습니다.");
                    navigate(`/appmg/qnamg`);
                }




            );
        }

    }

    const [mobile, setMobile] = useState("");
    return (
        <>

            <div className="common-wrap">
                <div className={line.qna_wrap}>

                    <div className={line.qna_box}>
                        <div className={line.qna_content}>
                            <div className={line.qna_content_top}>
                                <div className={line.qna_inner}>
                                    <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />


                                    <div className={`${line.qna_menu_li}`}>1:1 문의 상세</div>


                                </div>
                                <button onClick={removeQna}>삭제</button>


                            </div>

                            <QnaInfoWrap setMobile={setMobile} mobile={mobile} />

                        </div>  </div>
                </div>
            </div>
        </>
    )
}

export default AppQnaInfo