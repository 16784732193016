import {
    useTable,
    useRowSelect,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from "react-table";
import manage from "./table.module.css"
import { useNavigate } from "react-router-dom";
const NomalTable1 = ({ columns, data, length, type }) => {

    const prePage = () => {
        previousPage();
    };
    const nexPage = () => {
        nextPage();
    };

    const navigate = useNavigate();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
        setGlobalFilter,
        globalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,

    );
    const move_data = (item) => {
        if (type === "company_number") {
        }
        if (type === "company_bus") {
        }
        if (type === "company_driver") {
        }
    }
    // Render the UI for your table
    return (
        <>

            <div className={type === "company" ? `${manage.manage_table_box} ${manage.manage_table_box_div} ` : `${manage.manage_table_box} `} >



                <table className={manage.manage_table} {...getTableProps()}>
                    <thead className={manage.manage_thead}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className={manage.manage_tbody} {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr style={{ cursor: "pointer" }} {...row.getRowProps()} key={i} onClick={() => move_data(row.original)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {data.length === 0 &&

                    <div style={{ width: "100%", textAlign: "center" }}><span>데이터가 존재하지 않습니다</span></div>

                }
                {type === "race2" && <div className={manage.manage_table_bottom} >

                    <div >
                        {pageIndex >= 10 && (
                            <button
                                onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}
                            >
                                {"<"}
                            </button>
                        )}
                        {pageOptions.map((page, index) => {
                            if (pageIndex < 10 && index < 10) {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => gotoPage(index)}
                                        style={{
                                            fontWeight: pageIndex === index ? "bold" : "normal",
                                            // color: pageIndex === index ? "#fff" : "#000",
                                            // background:
                                            //     pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        {index + 1}
                                    </button>
                                );
                            } else if (pageIndex >= 10) {
                                const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
                                const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
                                const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
                                if (index >= startPageIndex && index < endPageIndex) {
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => gotoPage(index)}
                                            style={{
                                                fontWeight: pageIndex === index ? "bold" : "normal",



                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {index + 1}
                                        </button>
                                    );
                                }
                            }
                            return null;
                        })}
                        {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
                            <button
                                onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}
                            >
                                {">"}
                            </button>
                        )}
                    </div>
                </div>}
            </div>
        </>
    );
}
export default NomalTable1;