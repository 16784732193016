import { useEffect, useMemo, useState } from "react";
import manage from "../../../styles/busmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
const RouteInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const { idx } = useParams()
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        },
        {
            Header: "노선",
            accessor: "route_name",
            Cell: (row) => {
                let data = row.cell;
                let oriNo = row.cell.row.original.no;
                let thumbnail = row.cell.row.original.event_thumbnail;

                return (
                    <>
                        <div
                            className="click_detail"
                            style={{ textAlign: "left", paddingLeft: "20px" }}

                        >
                            <img
                                src={thumbnail}
                                style={{ width: "60px", marginRight: "10px" }}
                            />
                            {data.value}
                        </div>
                    </>
                );
            },
        },
        {
            Header: "노출여부",
            accessor: "route_open",
        },
        {
            Header: "버스번호수",
            accessor: "busCnt",
        },

        {
            Header: "등록일",
            accessor: "route_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },

    ]);

    //accessor 스타일링
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const DetailInfo = (oriNo) => {
        navigate(`/admin/event/${oriNo}`);
    };


    const data = [
        {}
    ]
    const cookies = new Cookies()


    const [tokenSwitch, setTokenSwitch] = useState(false);
    useEffect(() => {




        let sendData = {
            no: idx,
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/route_table",
            sendData,
            function (result) {
                console.log(result);
                if (result.messageinfo) {
                    console.log(result.messageinfo)
                    if (result.messageinfo.message.length > 0) {
                        setRouteData(result.messageinfo.message);
                        // setTokenSwitch(true);
                    } else {
                        // setTokenSwitch(true);
                    }
                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })

    }, [tokenSwitch])


    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>노선 관리 </span>
                <button onClick={() => { navigate('/busmg/routemg/routeInfo/0') }}>등록하기</button>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    {routeData && <NomalTable columns={columns} data={routeData} type="route" />}
                </div>
            </div>
        </div>
    </div >
}

export default RouteInner