import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import { combineReducers } from "redux";
import userSlice from "../slices/user";
import controlSlice from "../slices/control";


const rootReducer = combineReducers({
    user: userSlice.reducer,
    control: controlSlice.reducer,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    // middleware: [],
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
    devTools: true,
});





export default store;