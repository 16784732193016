import Menu from "../components/manage/common/Menu"
import BusStatInner from "../components/manage/statistics/BusStatInner"


const StatisticsBusMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'statistics'} />
            <BusStatInner />
        </div>
    )
}

export default StatisticsBusMg