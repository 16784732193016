import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { CommonAxios } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RaceMap from "./RaceMap";
import NomalTable from "../table/NomalTable";
import Moment from "react-moment";
import NomalTable1 from "../table/NomalTable1";
const { kakao } = window;
const RaceInfoWrap = ({ setClose, setViewData, viewData, close }) => {


    const { idx } = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const columns = useMemo(() => [

        {
            Header: "No",
            accessor: "ROWNUM",

        },
        {
            Header: "시간",
            accessor: "rut_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },

        {
            Header: "Accurate",
            accessor: "rut_accuracy",

        },


    ]);
    const columns2 = useMemo(() => [

        {
            Header: "날짜",
            accessor: "record_start_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "차량번호",
            accessor: "list_car_number",

        },


        {
            Header: "기사이름",
            accessor: "user_name",

        },
        // {
        //     Header: "운행시작 시",
        //     accessor: "record_start_date",
        //     Cell: ({ cell: { value } }) => <Date value={value} />,

        // },

        {
            Header: "운행종료 시",
            accessor: "record_end_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,

        },

        {
            Header: "운행시간",
            accessor: "ti",

        },

        {
            Header: "콜 숫자",
            accessor: "cnt",

        },



    ]);
    const Date = ({ value }) => {

        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const [routeInfo, setRouteInfo] = useState([]);
    const [routeBus, setRouteBus] = useState([]);
    const [routeName, setRouteName] = useState("");
    const [routeMemo, setRouteMemo] = useState("");
    const [routeOpen, setRouteOpen] = useState(true);
    const [busList, setBusList] = useState([])
    const [view, setView] = useState(false);
    const [selectV, setSelectV] = useState("");
    const [regBus, setRegBus] = useState("");
    const [regView, setRegView] = useState(false);
    useEffect(() => {




        let sendData = {
            record_id: viewData
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/race_detail",
            sendData,
            function (result) {
                // console.log(result.messageinfo.message);
                setRouteInfo(result.messageinfo.message);
                setRouteBus(result.messageinfo.message_data2);
                // if (result.result.length > 0) {

                // }
            })


    }, [])
    const modal_close = () => {


        setClose(false);
        setViewData("");
    }

    const [deleteData, setDeleteData] = useState([]);
    return (
        <>
            <div className={line.race_detail_wrap}>
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: "10px 20px" }}>
                    <button style={{ fontSize: "24px" }} onClick={modal_close}>x</button>
                </div>
                {routeBus.length > 0 && <NomalTable1 columns={columns2} data={routeBus} type={"raceData"} />}
                {routeInfo.length > 0 && <RaceMap routeInfo={routeInfo} />}
                {routeInfo.length > 0 && <NomalTable1 columns={columns} data={routeInfo} type={"race2"} />}

            </div></>)
}

export default RaceInfoWrap