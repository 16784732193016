import Menu from "../components/manage/common/Menu"
import CallInner from "../components/manage/operatemg/CallInner"
import MoniterInner from "../components/manage/operatemg/MoniterInner"


const OperateMoniterMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'operate'} />
            {/* <StopInner /> */}

            <MoniterInner />
        </div>
    )
}

export default OperateMoniterMg