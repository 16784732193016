import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
const StopInfoWrap = () => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    const dispatch = useDispatch();
    const [stopDate, setStopDate] = useState("");
    const [stopKind, setStopKind] = useState("");
    const [stopName, setStopName] = useState("");
    const [stopNumber, setStopNumber] = useState("");
    const [stopDirect, setStopDirect] = useState("");
    const [stopCon, setStopCon] = useState(1);
    const [stopLat, setStopLat] = useState("");
    const [stopLong, setStopLong] = useState("");
    const columns = useMemo(() => [
        // {
        //     Header: "No",
        //     accessor: "ROWNUM",
        //     Cell: (row) => {
        //         let count = row.data.length;
        //         let { index } = row.cell.row;

        //         return (
        //             <>
        //                 <div>{count - index}</div>
        //             </>
        //         );
        //     },
        // },
        {
            Header: "버스번호",
            accessor: "bus_number",
            // Cell: (row) => {
            //     let data = row.cell;
            //     let oriNo = row.cell.row.original.no;
            //     let thumbnail = row.cell.row.original.event_thumbnail;

            //     return (
            //         <>
            //             <div
            //                 className="click_detail"
            //                 style={{ textAlign: "left", paddingLeft: "20px" }}

            //             >
            //                 <img
            //                     src={thumbnail}#FF0000
            //                     style={{ width: "60px", marginRight: "10px" }}
            //                 />
            //                 {data.value}
            //             </div>
            //         </>
            //     );
            // },
        },
        {
            Header: "등록된 차량",
            accessor: "busCnt",
            Cell: (row) => {
                let data = row.cell;


                return (
                    <>

                        <p>
                            {data.value}
                            대 </p>
                    </>
                );
            },
        },


    ]);

    const [routeInfo, setRouteInfo] = useState("");
    const [routeBus, setRouteBus] = useState([]);
    const [routeName, setRouteName] = useState("");
    const [routeMemo, setRouteMemo] = useState("");
    const [routeOpen, setRouteOpen] = useState(true);

    useEffect(() => {

        if (idx !== "0") {
            let sendData = {
                stop_number: idx
            };

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/stop_detail_info",
                sendData,
                function (result) {
                    console.log(result);
                    if (result.messageinfo) {
                        setStopDate(moment(result.messageinfo.message[0].stop_date).format("YYYY-MM-DD HH:mm:ss"));
                        setStopName(result.messageinfo.message[0].stop_name);
                        setStopNumber(result.messageinfo.message[0].stop_number);
                        setStopDirect(result.messageinfo.message[0].stop_direction);
                        setStopCon(result.messageinfo.message[0].stop_connection);
                        setStopLat(result.messageinfo.message[0].stop_latitude);
                        setStopLong(result.messageinfo.message[0].stop_longitude);
                        let string = "";
                        result.messageinfo.message_data2.map((data) => {
                            string += `${data.route_name},`
                        })
                        if (string) {
                            setRouteInfo(string.slice(0, -1))
                        }
                    }
                })
        }
    }, [])



    const changeName = useCallback((e) => {
        setStopName(e.target.value)
    }, [])
    const changeNumber = useCallback((e) => {
        setStopNumber(e.target.value)
    }, [])


    const changeDirect = useCallback((e) => {
        setStopDirect(e.target.value)
    }, [])
    const changeCon = (e) => {
        setStopCon(e);

    }
    const changeLat = useCallback((e) => {
        setStopLat(e.target.value)
    }, [])
    const changeLong = useCallback((e) => {
        setStopLong(e.target.value)
    }, [])
    const btn_save = () => {

        let sendData = {
            stop_name: stopName,
            stop_number: stopNumber,
            stop_direction: stopDirect,
            stop_latitude: stopLat,
            stop_longitude: stopLong,
            stop_connection: stopCon,

        }
        let record = {
            record_category: "정류장 관리",
            record_user: user.uniq,
            record_text: `''${stopNumber}''라는 정류장 번호를 등록하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (idx === '0') {
            CommonAxiosToken(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/stop_insert",
                sendData,
                function (result) {

                    if (result.messageinfo) {
                        if (result.messageinfo.message === "이미 사용된 정류장이 있습니다.") {
                            window.alert("이미 사용하고 있는 정류장 번호입니다.")
                        } else {
                            AdminRecordAxios(record);
                            window.alert("저장 완료");
                            navigate(`/busmg/stopmg`);
                        }


                    }


                })

        } else {
            let record = {
                record_category: "정류장 관리",
                record_user: user.uniq,
                record_text: `''${stopNumber}''라는 정류장 번호를 수정하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxiosToken(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/stop_update",
                sendData,
                function (result) {

                    if (result.messageinfo) {

                        AdminRecordAxios(record);
                        window.alert("수정 완료")
                        navigate(`/busmg/stopmg`);
                    }

                })
        }
    }
    const btn_name_save2 = () => {

        let sendData = {
            no: idx,
            route_info: routeMemo,

            type: 2,
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_route_info",
            sendData,
            function (result) {

                if (result.result) {
                    window.alert("수정 완료")

                }

            })


    }

    const btn_insert = () => {

        let sendData = {
            value: routeName
        }

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route",
            sendData,
            function (result) {
                console.log(result);
                if (result.result) {

                    navigate(`/busmg/routemg/routeInfo/${result.result}`)
                }

            })


    }
    const data = [{}]
    return (
        <>

            <div className={line.stop_info_wrap}>
                <div className={line.stop_info_box}>
                    <div className={line.stop_info_table}>
                        {idx !== '0' &&
                            <>
                                <div className={line.stop_info_row}>
                                    <span className={line.stop_info_span}>등록일</span>
                                    <div className={line.stop_info_input}>
                                        <p>{stopDate}</p>
                                    </div>
                                </div>
                                <div className={line.stop_info_row}>
                                    <span className={line.stop_info_span}>노선정보</span>
                                    <div className={line.stop_info_input}>
                                        <p>{routeInfo}</p>
                                    </div>
                                </div>
                            </>
                        }
                        <div className={line.stop_info_row}>
                            <span className={line.stop_info_span}>정류장명</span>
                            <div className={line.stop_info_input}>
                                <input type="text" value={stopName} onChange={changeName} />
                            </div>
                        </div>
                        <div className={line.stop_info_row}>
                            <span className={line.stop_info_span}>정류장번호</span>
                            <div className={line.stop_info_input}>
                                <input type="text" value={stopNumber} onChange={changeNumber} disabled={idx === "0" ? false : true} />
                            </div>
                        </div>
                        <div className={line.stop_info_row}>
                            <span className={line.stop_info_span}>방면정보</span>
                            <div className={line.stop_info_input}>
                                <input type="text" value={stopDirect} onChange={changeDirect} />
                            </div>
                        </div>
                        <div className={line.stop_info_row}>
                            <span className={line.stop_info_span}>화상연결</span>
                            <div className={line.stop_info_input}>
                                <div>
                                    <label>
                                        <input type="radio" name="connect" onChange={() => changeCon(1)} checked={stopCon === 1 ? true : false} />설치
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" name="connect" onChange={() => changeCon(2)} checked={stopCon === 2 ? true : false} />미설치
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={line.stop_info_row}>
                            <span className={line.stop_info_span}>지도좌표</span>
                            <div className={line.stop_info_input}>
                                <div>
                                    <label>위도</label>
                                    <input type="text" value={stopLat} onChange={changeLat} />
                                </div>
                                <div>
                                    <label>경도</label>
                                    <input type="text" value={stopLong} onChange={changeLong} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={line.stop_save}>
                        <button onClick={btn_save}>저장</button>
                    </div>

                </div>

            </div></>)
}

export default StopInfoWrap