import React, { useRef, useEffect, useState, useCallback } from 'react'
import logo1 from "./../../assets/logo/logo_1.svg"
import logo2 from "./../../assets/logo/logo_2.svg"
import logo3 from "./../../assets/logo/logo_3.svg"
import logo4 from "./../../assets/logo/logo_4.png"
import logo5 from "./../../assets/logo/logo_5.png"
import styles from "./footer.module.css";

const Footer = () => {

    return (
        <div className={styles.footer_wrap}>
            <span className={styles.footer_text}>본 괴산군 스마트 복합 대중교통 플랫폼은 국토교통부·충청북도 괴산군 「2022년 괴산군 중소도시 스마트시티 조성사업」의 지원을 받아 제작 되었습니다</span>
            <img src={logo1} />
            <img src={logo2} />
            <img src={logo3} style={{height:"24px"}} />
            <img src={logo4} />
            <img src={logo5} />
        </div>
    )

}

export default Footer;