import {
    useTable,
    useRowSelect,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from "react-table";
import manage from "./table.module.css"
import { useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useRef } from "react";
import { CommonAxios } from "../../../module/CommonAxios";
const InfopTable = ({ columns, data, setDeleteData }) => {
    useEffect(() => {

    }, [])
    const deleteUser = () => {
        let deleteArr = [];

        for (let a = 0; a < selectedFlatRows.length; a++) {
            deleteArr.push(selectedFlatRows[a].original.no);
        }

        if (deleteArr.length === 0) {
            window.alert("삭제하실 버스 번호를 클릭해주세요.");
        }
    }
    //체크박스 옵션
    const IndeterminateCheckbox = forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = useRef();
            const resolvedRef = ref || defaultRef;

            useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            return (
                <>
                    <input
                        type="checkbox"
                        ref={resolvedRef}
                        {...rest}
                        style={{ width: "32px" }}
                    />
                </>
            );
        }
    );
    const prePage = () => {
        previousPage();
    };
    const nexPage = () => {
        nextPage();
    };

    const navigate = useNavigate();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
        setGlobalFilter,
        globalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        // (hooks) => {
        //     hooks.visibleColumns.push((columns) => [
        //         // Let's make a column for selection
        //         {
        //             id: "selection",
        //             // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
        //             // getToggleAllRowsSelectedProps : 모든 row 전체 선택
        //             Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox
        //                         {...getToggleAllPageRowsSelectedProps()}
        //                     />
        //                 </div>
        //             ),
        //             // The cell can use the individual row's getToggleRowSelectedProps method
        //             // to the render a checkbox
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );
    const move_data = (item) => {
        navigate(`/busmg/routemg/routeInfo/${item}`)

    }
    // Render the UI for your table

    const deleteItem = (no) => {

        const result = window.confirm("해당 번호를 삭제하시겠습니까?");
        if (result) {
            let sendData = {
                value: no
            }

            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/route_update_bus",
                sendData,
                function (result) {

                    if (result.result) {
                        if (result.result === "완료") {
                            window.alert("수정되었습니다.")
                        }

                        // navigate(`/busmg/routemg/routeInfo/${result.result}`)
                    }

                })


        } else {

        }
    }
    return (
        <>



            <table className={manage.info_table} {...getTableProps()}>
                <thead className={manage.info_thead}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? " 🔽"
                                                : " 🔼"
                                            : ""}
                                    </span>
                                </th>

                            ))}
                            <th style={{ width: "120px" }}></th>
                        </tr>
                    ))}
                </thead>  {data[0].bus_number &&
                    <tbody className={manage.info_tbody} {...getTableBodyProps()}>



                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} onClick={() => move_data(row.original.no)} >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>

                                        );
                                    })}
                                    <td><button onClick={() => deleteItem(row.original.no)} style={{
                                        color: "#fff",
                                        background: "#ff0000",
                                        width: "80px",
                                        height: "30px",
                                        borderRadius: "5px"
                                    }}>삭제</button></td>
                                </tr>
                            );
                        })

                        }
                    </tbody>}
            </table>
            {!data[0].bus_number &&

                <div style={{ width: "100%", textAlign: "center" }}><span>데이터가 존재하지 않습니다</span></div>

            }


        </>
    );
}
export default InfopTable;