import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import styles from "./../../styles/main.module.css";
import phone_icon from "./../../assets/icons/phone_call.png";
import bus_icon from "./../../assets/icons/bus_call.png";
import taxi_icon from "./../../assets/icons/taxi_call.png";
import { Scrollbars } from 'react-custom-scrollbars';


const Menu = ({callList, submitCall}) => {

    const [bCall, setBCall] = useState([]);

    useEffect(() => {
        if(callList.length > 0){
            setBCall(callList);
        } else {
            setBCall([]);
        }
    }, [callList])

    const callFunc = (a) => {
        submitCall(a);
    }

    return (
        <>
            {/* <div className={styles.main_call_info}>
                <div className={styles.call_info_area}>
                    <div className={styles.call_info_box}>
                        <img src={phone_icon} />
                        <div className={styles.call_title}>대표번호 콜</div>
                    </div>
                    <div className={styles.call_info_view}>
                        <span>0</span>
                    </div>
                </div>
                <div className={styles.call_info_list}>
                </div>
            </div>
            <hr /> */}
            <div className={styles.main_call_info}>
                <div className={styles.call_info_area}>
                    <div className={styles.call_info_box}>
                        <img src={bus_icon} />
                        <div className={styles.call_title}>버스 정류장 콜</div>
                    </div>
                    <div className={styles.call_info_view}>
                        <span>{bCall.length}</span>
                    </div>
                </div>
                <div className={styles.call_info_list}>
                    {bCall.length > 0 ?
                        (bCall.length > 7 ?
                            <Scrollbars className={styles.ul_area} style={{ width: 340, height: 185 }}>
                                {bCall.map((a) => {
                                    return(
                                        <div className={styles.call_list_div}>
                                            <div className={styles.call_list_name} >{a.stop_name}</div>
                                            <button className={styles.call_list_btn} onClick={() => callFunc(a)}>받기</button>
                                        </div>
                                    )
                                })}
                            </Scrollbars>
                            :
                            bCall.map((a) => {
                                return(
                                    <div className={styles.call_list_div}>
                                        <div className={styles.call_list_name} >{a.stop_name}</div>
                                        <button className={styles.call_list_btn} onClick={() => callFunc(a)}>받기</button>
                                    </div>
                                )
                            })
                        )
                        :
                        null
                    }
                </div>
            </div>
            <hr />
            <div className={styles.main_call_info}>
                <div className={styles.call_info_area}>
                    <div className={styles.call_info_box}>
                        <img src={taxi_icon} />
                        <div className={styles.call_title}>택시 앱 콜</div>
                    </div>
                    <div className={styles.call_info_view}>
                        <span>0</span>
                    </div>
                </div>
                <div className={styles.call_info_list}>
                    
                </div>
            </div>
        </>
    )
}

export default Menu;