import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { AdminRecordAxios, CommonAxios } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const RouteInfoWrap = () => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const columns = useMemo(() => [

        {
            Header: "버스번호",
            accessor: "bus_number",

        },
        {
            Header: "등록된 차량",
            accessor: "busCnt",
            Cell: (row) => {
                let data = row.cell;


                return (
                    <>

                        <p>
                            {data.value}
                            대 </p>
                    </>
                );
            },
        },


    ]);

    const [routeInfo, setRouteInfo] = useState([]);
    const [routeBus, setRouteBus] = useState([]);
    const [routeName, setRouteName] = useState("");
    const [routeMemo, setRouteMemo] = useState("");
    const [routeOpen, setRouteOpen] = useState(true);
    const [busList, setBusList] = useState([])
    const [view, setView] = useState(false);
    const [selectV, setSelectV] = useState("");
    const [regBus, setRegBus] = useState("");
    const [regView, setRegView] = useState(false);
    const selectRoute = () => {

        let sendData = {
            no: idx
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/route_detail_info",
            sendData,
            function (result) {
                if (result.result.length > 0) {
                    setRouteInfo(result.result);
                    setRouteName(result.result[0].route_name);
                    setRouteMemo(result.result[0].route_info);
                    setRouteOpen(result.result[0].route_open === "1" ? false : true);
                    setRouteBus(result.result2);
                    setBusList(result.result3)
                    // if (result.result3) {
                    //     let arr = result.result3.filter(item => {
                    //         return !result.result2.some(other => other.no === item.no)
                    //     })

                    //     setBusList(arr);
                    // }
                    // console.log(result.result2.length)
                }
            })

    }
    useEffect(() => {

        if (idx !== "0") {
            selectRoute();
        }
    }, [location.pathname])



    const changeName = useCallback((e) => {
        setRouteName(e.target.value)
    }, [])

    const checkOpen = (e) => {
        setRouteOpen(e.target.checked);

    }
    const changeMemo = useCallback((e) => {
        setRouteMemo(e.target.value)
    }, [])

    const btn_name_save = () => {

        let sendData = {
            no: idx,
            route_name: routeName,
            route_open: routeOpen ? 0 : 1,
            type: 1,
        }
        let record = {
            record_category: "노선 관리",
            record_user: user.uniq,
            record_text: `''${routeName}''의 노선명을 수정하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_route_info",
            sendData,
            function (result) {

                if (result.result) {
                    AdminRecordAxios(record)
                    window.alert("수정 완료")

                }

            })


    }
    const btn_name_save2 = () => {

        let sendData = {
            no: idx,
            route_info: routeMemo,

            type: 2,
        }
        let record = {
            record_category: "노선 관리",
            record_user: user.uniq,
            record_text: `''${routeName}''의 노선정보를 수정하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_route_info",
            sendData,
            function (result) {

                if (result.result) {
                    AdminRecordAxios(record)
                    window.alert("수정 완료")

                }

            })


    }
    const user = useSelector(state => state.user);

    const btn_insert = () => {

        let sendData = {
            value: routeName
        }
        let record = {
            record_category: "노선 관리",
            record_user: user.uniq,
            record_text: `''${routeName}''라는 노선을 등록하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route",
            sendData,
            function (result) {

                if (result.result) {
                    AdminRecordAxios(record)
                    navigate(`/busmg/routemg/routeInfo/${result.result}`)
                }

            })


    }
    const data = [{}]

    const changeSelect = (e) => {
        setSelectV(e.target.value);
    }

    const insertBus = () => {

        let sendData = {
            value: selectV,
            no: idx,
        }
        let record = {
            record_category: "노선 관리",
            record_user: user.uniq,
            record_text: `''${routeName}''의 버스가 추가되었습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/route_add_bus",
            sendData,
            function (result) {
                console.log(result);
                if (result.result) {
                    if (result.result === "완료") {
                        setView(false);
                        setRegView(false);
                        window.alert("등록되었습니다.");
                        AdminRecordAxios(record)
                    } else {
                        setRegView(true);
                        setRegBus(result.result);
                    }
                    // selectRoute();
                    // navigate(`/busmg/routemg/routeInfo/${result.result}`)
                }

            })

    }

    const modal_close = () => {


        setView(false);
        setRegView(false);
    }

    const [deleteData, setDeleteData] = useState([]);
    return (
        <>
            {view && <div className={line.route_map_modal}>
                <div className={line.route_modal_inner}>
                    <div className={line.route_modal_title}>
                        <span>버스 번호</span>
                    </div>

                    <div className={line.route_modal_content}>
                        <div className={line.route_modal_list}>
                            <select onChange={changeSelect}>
                                <option>버스 번호 선택</option>
                                {busList && busList.map((data) => {

                                    return <option value={data.no}>{data.bus_number}</option>
                                })}
                            </select>
                            {/* {searchData.length === 0 && searchStop && searchStop.map((data, index) => {
                                return <SearchItem item={data} index={index} />
                            })} */}
                            {/* {searchData.length > 0 && searchData.map((data, index) => {
                                return <SearchItem item={data} index={index} />
                            })} */}

                            {regView && <p>
                                이미 등록되어있는 번호입니다.<br />
                                해당 노선에서 삭제 후 진행해주시길 바랍니다.<br />
                                ({regBus})
                            </p >}
                        </div>
                    </div>
                    <div className={line.route_modal_btn}>
                        <button
                            onClick={
                                () => modal_close()}
                        >취소</button>
                        <button
                            onClick={insertBus}
                        >등록</button>
                    </div>
                </div>
            </div>}
            <div className={line.route_info_wrap}>
                <div className={line.route_info_box}>
                    <div className={line.route_info_top}>
                        <div className={line.route_info_title}><span>노선명</span></div>
                        <div className={line.route_info_div}>
                            <input type="text" value={routeName} onChange={changeName} />
                            {idx === "0" && <><button onClick={btn_insert}>저장</button></>}
                            {idx !== "0" && <>
                                <div>
                                    <span>고객앱 비노출</span>
                                    <input type="checkbox" checked={routeOpen} onChange={(e) => checkOpen(e)} />
                                </div>
                                <button onClick={btn_name_save}>저장</button></>}
                        </div>

                    </div>
                    {idx !== "0" && <>
                        <div className={line.route_info_middle}>
                            <div className={line.route_info_title}><span>노선정보</span><button onClick={btn_name_save2}>저장</button></div>
                            <textarea value={routeMemo} onChange={changeMemo}></textarea>

                        </div>
                        <div className={line.route_info_bottom}>
                            <div className={line.route_info_title}>
                                <span>등록한 버스 번호</span>
                                <div>
                                    <button onClick={() => setView(true)}>추가</button>
                                </div>
                            </div>

                            <div>
                                {routeBus && routeBus.length > 0 && routeBus[0].bus_number && <InfopTable columns={columns} data={routeBus} />}
                                {routeBus && routeBus.length > 0 && !routeBus[0].bus_number &&

                                    <InfopTable columns={columns} data={routeBus} setDeleteDate={setDeleteData} />
                                }
                            </div>
                        </div></>}

                </div>

            </div></>)
}

export default RouteInfoWrap