import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
// import { textReplace } from "../../lib/textReplace/textReplace";
import { textReplace } from "../../../lib/textReplace";

// Toast 에디터
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";

// Toast 플러그인
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import axios from "axios";
const Wrap = styled.div`
  width: 630px;
  // margin: 0 auto;
  // position: relative;
`;

const TostEditor = ({ setContent, content }) => {
  const dispatch = useDispatch();
  // Editor DOM 선택용
  const editorRef = useRef();

  const onChange = () => {
    let data = editorRef.current.getInstance().getHTML();
    setContent(textReplace(data))
    // dispatch(changeContent(data));
  };
  const [imgTemp, setImgTemp] = useState("");
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook("addImageBlobHook");

      editorRef.current
        .getInstance()
        .addHook("addImageBlobHook", (blob, callback) => {
          (async () => {

            let fileName =
              new Date().getTime();
            let splitFileName = blob.name.split(".");
            fileName = `${fileName}.${splitFileName[splitFileName.length - 1]}`;

            setImgTemp(fileName);
            const formData = new FormData();
            formData.append("filename", fileName);

            formData.append("imgFile", blob);
            console.log(">?)")
            axios
              .post(
                process.env.REACT_APP_HOSTDONAME + "/api/notice_upload_img",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                if (response.data.messageinfo.state === "ok") {

                  callback(`/notice/temp_img/${fileName}`, "alt_text");
                }
              })
              .catch((error) => {
                // 예외 처리
              });
          })();

          return false;
        });
    }
  }, [editorRef]);

  return (
    <>
      <Wrap>
        <form>
          <Editor
            ref={editorRef} // DOM 선택용 useRef
            // placeholder="내용을 입력해주세요."
            previewStyle="vertical" // 미리보기 스타일 지정
            height="400px" // 에디터 창 높이
            initialEditType="wysiwyg" // 기본 에디터 타입
            toolbarItems={[
              // 툴바 옵션 설정
              // ["heading", "bold", "italic", "strike"],
              // ["hr", "quote", "link"],
              // ["ul", "ol", "task", "indent", "outdent"],
              ["heading", "bold", "italic", "strike"],
              ["hr", "quote"],
              ["ul", "ol", "task", "indent", "outdent"],
              ["image", "link"],
              ["code", "codeblock"],
            ]}
            linkAttributes={{
              target: "_blank", //A태그 새창열기
              contenteditable: "false",
            }}
            hideModeSwitch={true}
            onChange={onChange}
            initialValue={content ? content : ""}
            autofocus={false}
            useCommandShortcut={false} // 키보드 입력 컨트롤 방지
            plugins={[colorSyntax]} // 색상 플러그인 적용
          ></Editor>
        </form>
      </Wrap>
    </>
  );
};

export default TostEditor;
