import Menu from "../components/manage/common/Menu"
import CallInner from "../components/manage/operatemg/CallInner"
import StopInner from "../components/manage/operatemg/StopInner"


const OperateStopMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'operate'} />
            {/* <StopInner /> */}

            <StopInner />
        </div>
    )
}

export default OperateStopMg