import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/admin.module.css"
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";

import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { FaBus } from "react-icons/fa";
import { BiSolidPhoneCall, BiPhoneCall } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";


const FirstBusBox = () => {
    const navigate = useNavigate();
    const [insertVale, setInsertValue] = useState("");

    const [data, setData] = useState([]);

    const dispatch = useDispatch();





    const [tokenSwitch, setTokenSwitch] = useState(false);


    const [route, setRoute] = useState(0);
    const [bus, setBus] = useState(0);
    const [call, setCall] = useState(0);
    const getData = () => {


        let sendData = {

            where: "",
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/control_select_top",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    setRoute(result.messageinfo.message[0].routeCnt);
                    setBus(result.messageinfo.message_data2[0].busCnt);
                    setCall(result.messageinfo.message_data3[0].callCnt);
                } else {

                }

            })
    }

    useEffect(() => {
        setInterval(() => {
            getData();
        }, 1000)

    }, [])

    const user = useSelector(state => state.user);


    return <div className={manage.admin_box}>
        <div className={manage.admin_box_inner}>
            <div className={manage.admin_box_top}>
                <h3>버스 관제</h3>
            </div>
            <div className={manage.admin_box_middle}>
                <div>
                    <ul>
                        <li>
                            <Link to="">
                                <div className={manage.box_middle_inner}>
                                    <div>
                                        <span>노선 운행 현황</span>
                                        <FaLocationDot />

                                    </div>
                                    <div>
                                        <p>{route}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <div className={manage.box_middle_inner}>
                                    <div>

                                        <span>버스 운행 현황
                                        </span>
                                        <FaBus /></div>
                                    <div>
                                        <p>{bus}</p>
                                    </div></div>
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <div className={manage.box_middle_inner}>
                                    <div>

                                        <span>오늘의 콜 현황</span>
                                        <BiSolidPhoneCall /></div>
                                    <div>
                                        <p>{call}</p>
                                    </div></div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            < div className={manage.admin_box_bottom}>
                <ul>
                    <li> <Link to="">
                        <div><BiPhoneCall />
                            <span>
                                버스 콜 현황</span></div>
                    </Link>
                    </li>
                    <li><Link to="/control/bus"><div>
                        <IoSettingsOutline />
                        <span>
                            관제 화면 열기</span></div>
                    </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div >
}

export default FirstBusBox