import Menu from "../components/manage/common/Menu"
import CallStatInner from "../components/manage/statistics/CallStatInner"


const StatisticsCallMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'statistics'} />
            <CallStatInner />
        </div>
    )
}

export default StatisticsCallMg