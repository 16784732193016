import FaqInner from "../components/manage/appmg/FaqInner"
import TermInner from "../components/manage/appmg/TermInner"
import Menu from "../components/manage/common/Menu"


const AppTermMg = () => {


    return (

        <div style={{ display: "flex", minHeight: "calc(100vh-60)" }}>
            <Menu type={'user'} />
            <TermInner />

        </div>
    )
}

export default AppTermMg