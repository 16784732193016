import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { CommonAxios } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const { kakao } = window;
const RaceMap = ({ routeInfo }) => {

    const [mapInfo, setMaps] = useState(null);          // 카카오 지도 데이터
    useEffect(() => {
        const container = document.getElementById('raceMap');
        const options = {
            // center: new kakao.maps.LatLng(36.806744385094596, 127.80419313104726),
            center: new kakao.maps.LatLng(36.63846052508691, 127.44473659298507),
            level: 6
        }
        const map = new kakao.maps.Map(container, options);
        // setMaps(map);

        let aaaa = [];

        routeInfo.map((data2) => {

            aaaa.push(new kakao.maps.LatLng(data2.rut_latitude, data2.rut_longitude))






        })

        var polyline = new kakao.maps.Polyline({
            path: aaaa, // 선을 구성하는 좌표배열 입니다
            strokeWeight: 5, // 선의 두께 입니다
            strokeColor: 'rgb(0, 78, 162)', // 선의 색깔입니다
            strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid' // 선의 스타일입니다
        });
        polyline.setMap(map);
    }, [])

    const navigate = useNavigate();


    return (
        <>
            <div id="raceMap" style={{ width: "100%", height: `400px` }}></div></>)
}

export default RaceMap