import axios, { AxiosError, all } from 'axios';
// import Swal from "sweetalert2";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Cookies } from 'react-cookie'
const cookies = new Cookies()
export function CommonAxios(url, sendData, result) {

  const options = {
    method: "POST",
    header: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTP-8",


    },
    data: sendData,
  };

  axios(url, options)
    .then((response) => {

      result(response.data);
    })
    .catch((err) => console.error("오류"));
}
export const AdminRecordAxios = async (sendData) => {
  const response = await axios.post(
    process.env.REACT_APP_HOSTDONAME + "/api/admin/record_insert",
    sendData,

  );
}
export function CommonAxiosToken(url, sendData, result) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTP-8",

      'Authorization': `Bearer ${cookies.get('acc')}`,
    },
    data: sendData,
  };

  axios(url, options)
    .then((response) => {

      // const { accessToken } = response.data;
      // console.log(response.data.accessToken)
      // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      result(response.data);
    })
    .catch((error) => {
      const errorRespons = (error).response;

      result(errorRespons);
      // const options2 = {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json;charset=UTP-8",


      //   },

      // };
      // if (errorRespons.data.message === "만료된 액세스 토큰입니다.") {
      //   axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
      //     .then((response) => {


      //       axios(url, options)
      //         .then((response) => {

      //           result(response.data);
      //         })
      //         .catch((error) => { })
      //     }).catch((error) => {

      //     })
      // }
    }

    );

}
export function CommonGetAxios(url, no, result) {
  const options = {
    method: "GET",
    header: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTP-8",
    },
    params: {
      no: no,
    },
  };

  axios(url, options)
    .then((response) => {
      result(response.data);
    })
    .catch((err) => console.error("오류"));

}

export function CommonGetToken() {
  const location = useLocation();
  if (localStorage.getItem("serveInfo")) {
    const url = process.env.REACT_APP_HOSTDONAME + "/api/tokenEnd";

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "x-www-form-urlencoded",
      },
    };
    const FAIL = "쿠키가 삭제되었습니다.";
    axios.post(url, config).then((res) => {
      if (res.data.messageinfo.message === FAIL) {
        // Swal.fire({
        //   title: "로그인 만료",
        //   text: "로그인 페이지로 이동합니다.",
        //   allowOutsideClick: false,
        //   icon: "warning",
        //   confirmButtonColor: "#3085d6",
        //   confirmButtonText: "확인",
        // }).then((res) => {
        //   if (res.isConfirmed) {
        //     sessionStorage.setItem("savePage", location.pathname);
        //     localStorage.removeItem("serveInfo");
        //     document.location.href = "/login";
        //   }
        // });
      } else {
      }
    });
  } else {
  }
}
