import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/appmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
const UserInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const { idx } = useParams()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [osValue, setOsValue] = useState(0);
    const [cateValue, setCateValue] = useState(2);
    const [fromAge, setFromAge] = useState("");
    const [toAge, setToAge] = useState("");
    const [gender, setGender] = useState(0);
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            Header: "No",
            accessor: "ROWNUM",
            Cell: (row) => {
                let count = row.data.length;
                let { index } = row.cell.row;

                return (
                    <>
                        <div>{count - index}</div>
                    </>
                );
            },
        },
        {
            Header: "IDX",
            accessor: "user_uniq",
            Cell: ({ cell: { value } }) => <>{value}</>
        }, {
            Header: "가입일시",
            accessor: "user_reg_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },
        {
            Header: "가입단말",
            accessor: "user_os",
            Cell: ({ cell: { value } }) => <>{value === 1 ? "안드로이드폰" : "아이폰"}</>
        },
        {
            Header: "회원전화번호",
            accessor: "user_mobile",
            Cell: ({ cell: { value } }) => <Phone value={value} />,
        },
        {
            Header: "성별",
            accessor: "user_gender",
            Cell: ({ cell: { value } }) => <>{value === 1 ? "남" : "여"}</>
        },
        {
            Header: "나이",
            accessor: "user_age",
            Cell: ({ cell: { value } }) => <>{value ? value : ""}</>
            // Cell: ({ cell: { value } }) => <>{value ? moment().year() - value + 1 : ''}</>
        }, {
            Header: "탈퇴일자",
            accessor: "user_delete_date",
            Cell: (row) => {

                let data = row.cell.row.original.user_status;

                return (<>
                    {data === '1' ? "" : row.cell.row.original.user_delete_date}
                </>)

            }
            // Cell: ({ cell: { value } }) => <Date value={value} />,
        },


    ]);

    //accessor 스타일링
    const Phone = ({ value }) => {
        return <>{value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)}</>
    };
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const DetailInfo = (oriNo) => {
        navigate(`/admin/event/${oriNo}`);
    };


    const data = [
        {}
    ]
    const cookies = new Cookies()


    const [tokenSwitch, setTokenSwitch] = useState(false);

    const getData = () => {

        let string = "";
        let sendData = {
            startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: searchValue,
            where: "",
        };


        if (osValue !== 0) {
            string += ` AND user_os='${osValue}' `
        }
        if (cateValue !== 2) {
            string += ` AND user_status='${cateValue}' `
        }
        if (gender !== 0) {
            string += ` AND user_gender='${cateValue}' `
        }
        if (fromAge && toAge) {
            string += ` AND user_age BETWEEN '${fromAge}' AND '${toAge}'  `
        }

        if (fromAge && !toAge) {
            string += ` AND user_age >= '${fromAge}'  `
        }
        if (!fromAge && toAge) {
            string += ` AND user_age <= '${toAge}'  `
        }

        if (string) {
            sendData.where = string
        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/user_table",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    setRouteData(result.messageinfo.message);

                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {

        getData();


    }, [tokenSwitch])




    const searchFun = () => {
        getData();
    }
    const resetFun = () => {
        setStartDate("")
        setEndDate("");
        setSearchValue("");
        setOsValue(0);
        setCateValue(2);
        setGender(0);
        setFromAge("");
        setToAge("");


        let sendData = {
            startDate: "", endDate: moment().format("YYYY-MM-DD"), search: "",
            where: "",
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/user_table",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    setRouteData(result.messageinfo.message);

                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };
                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    const onValue = useCallback((e) => {
        setSearchValue(e.target.value)
    }, [])
    const changeOs = (e) => {
        setOsValue(e);

    }
    const changCate = (e) => {
        setCateValue(e);

    }
    const changeGender = (e) => {
        setGender(e);

    }
    return <div className={manage.manage_wrap}>
        <div className={manage.manage_box}>
            <div className={manage.manage_top}>
                <span>회원정보</span>
            </div>
            <div className={manage.manage_middle}>
                <div>
                    <div className={manage.mange_search_wrap}>
                        <div className={manage.manage_search_box}>

                            <div className={manage.manage_search_inner}>
                                <div className={manage.manage_date}>
                                    <label>기간</label>
                                    <span className={manage.manage_datepicker}>
                                        <span>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                locale={ko}
                                                onChange={(date) => setStartDate(date)}
                                                maxDate={endDate}
                                                selected={startDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                placeholderText={"YYYY.MM.DD"}
                                            />
                                        </span>
                                        <span className={manage.datepicker_ico}>
                                            <FaCalendar />
                                        </span>
                                    </span>
                                    <span>~</span>
                                    <span className={manage.manage_datepicker}>
                                        <span>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                locale={ko}
                                                onChange={(date) => setEndDate(date)}
                                                selected={endDate}
                                                maxDate={window.Date}
                                                startDate={endDate}
                                                endDate={endDate}
                                                placeholderText={"YYYY.MM.DD"}
                                            />
                                        </span>
                                        <span className={manage.datepicker_ico}>
                                            <FaCalendar />

                                        </span>
                                    </span>

                                </div>
                                <div className={manage.manage_search}>
                                    <label>검색어</label>
                                    <input type="text" placeholder="휴대폰번호" value={searchValue} onChange={onValue} />
                                </div>
                            </div>
                            <div className={manage.manage_search_inner2}>
                                <div className={manage.manage_search_div}>
                                    <label>가입단말</label>
                                    <div>
                                        <label><input type="radio" name="device" onChange={(e) => changeOs(0)} checked={osValue === 0 ? true : false} /> 전체 </label>
                                        <label><input type="radio" name="device" onChange={(e) => changeOs(1)} checked={osValue === 1 ? true : false} /> 안드로이드폰 </label>
                                        <label><input type="radio" name="device" onChange={(e) => changeOs(2)} checked={osValue === 2 ? true : false} /> 아이폰 </label>
                                    </div>

                                </div>
                                <div className={manage.manage_search_div}>
                                    <label>구분</label>
                                    <div>
                                        <label><input type="radio" name="category" onChange={(e) => changCate(2)} checked={cateValue === 2 ? true : false} /> 전체 </label>
                                        <label><input type="radio" name="category" onChange={(e) => changCate(1)} checked={cateValue === 1 ? true : false} /> 정상 </label>
                                        <label><input type="radio" name="category" onChange={(e) => changCate(0)} checked={cateValue === 0 ? true : false} /> 탈퇴 </label>
                                    </div>
                                </div>
                            </div>

                            <div className={manage.manage_search_inner2}>
                                <div className={manage.manage_search_div}>
                                    <label>나이</label>
                                    <div className={manage.manage_age_div}>
                                        <input type="text" value={searchValue} onChange={onValue} />
                                        <label>~</label>
                                        <input type="text" value={searchValue} onChange={onValue} />
                                    </div>

                                </div>
                                <div className={manage.manage_search_div}>
                                    <label>성별</label>
                                    <div>
                                        <label><input type="radio" name="gender" onChange={(e) => changeGender(0)} checked={gender === 0 ? true : false} /> 전체 </label>
                                        <label><input type="radio" name="gender" onChange={(e) => changeGender(1)} checked={gender === 1 ? true : false} /> 남성 </label>
                                        <label><input type="radio" name="gender" onChange={(e) => changeGender(2)} checked={gender === 2 ? true : false} /> 여성 </label>
                                    </div>
                                </div>
                            </div>
                            <div className={manage.manage_serach_button}>
                                <button onClick={resetFun}>초기화</button>
                                <button onClick={searchFun}>검색</button>
                            </div>
                        </div>

                    </div>
                    {routeData && <SearchTable columns={columns} data={routeData} type={"user"} length={routeData.length} />}
                </div>
            </div>
        </div>
    </div >
}

export default UserInner