import { useEffect, useState } from "react";
import { CommonAxiosToken } from "../../../module/CommonAxios";
import style from "../../../styles/control.module.css"

const ControlLeftTop = () => {
    const [route, setRoute] = useState(0);
    const [bus, setBus] = useState(0);
    const [call, setCall] = useState(0);
    const getData = () => {


        let sendData = {

            where: "",
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/control_select_top",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    setRoute(result.messageinfo.message[0].routeCnt);
                    setBus(result.messageinfo.message_data2[0].busCnt);
                    setCall(result.messageinfo.message_data3[0].callCnt);
                } else {

                }

            })
    }

    useEffect(() => {
        setInterval(() => {
            getData();
        }, 1000)

    }, [])

    return (
        <div className={style.left_top}>
            <ul>
                <li>
                    <p>노선 운행 현황</p>
                    <span>{route}</span>
                </li>
                <li>
                    <p>버스 운행 현황</p>
                    <span>{bus}</span>
                </li>
                <li>
                    <p>오늘의 콜 현황</p>
                    <span>{call}</span>
                </li>
            </ul>
        </div>
    )
}

export default ControlLeftTop